// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JMbwJlK0VEhkxYnCq82E{text-decoration:none;color:unset}`, "",{"version":3,"sources":["webpack://./src/Age/SkrivUtBeslut/SkrivUtBeslut.module.scss"],"names":[],"mappings":"AAAA,sBACC,oBAAA,CACA,WAAA","sourcesContent":[".DownloadLink {\n\ttext-decoration: none;\n\tcolor: unset;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DownloadLink": `JMbwJlK0VEhkxYnCq82E`
};
export default ___CSS_LOADER_EXPORT___;
