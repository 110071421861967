// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jklVb8NABbnBAlbjB6Ag{display:flex;align-items:center;gap:5px}.jklVb8NABbnBAlbjB6Ag a{text-decoration:none}`, "",{"version":3,"sources":["webpack://./src/ExternalLink/ExternalLink.module.scss"],"names":[],"mappings":"AAAA,sBACC,YAAA,CACA,kBAAA,CACA,OAAA,CAEA,wBACC,oBAAA","sourcesContent":[".Container {\n\tdisplay: flex;\n\talign-items: center;\n\tgap: 5px;\n\n\ta {\n\t\ttext-decoration: none;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": `jklVb8NABbnBAlbjB6Ag`
};
export default ___CSS_LOADER_EXPORT___;
