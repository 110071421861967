import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import FileUpload from '@local/Components/FileUpload'
import { setFieldValue as customSetFieldValue } from '@local/Utils/Helpers'
import { addDays, isBefore } from 'date-fns'
import { trrDate } from '@local/Utils/Helpers/formatDate'
import { generateErrorMessage } from '@local/Utils/Helpers/Forms/Forms.helpers'
import { DatePicker } from '@local/src/Components/DatePicker'
import { fileUploadContent } from '@local/src/Components/FileUpload/fileUpload.helpers'
import HTMLMapper from '@local/src/Components/HTMLMapper'
import { Grid } from '@mui/material'
import useResetForm from '@local/src/Utils/Hooks/useResetForm'
import { useTrackCustomClick } from '@trr/gtm-tracking'

import FormSubmit from '../../../Components/FormSubmit'

import {
  IAvgangsvederlag,
  IAvgangsvederlagFormValues,
} from './Avgangsvederlag.types'
import validationSchema from './Avgangsvederlag.schema'
import { getFormData } from './Avgangsvederlag.helper'

const initialValues: IAvgangsvederlagFormValues = {
  vederlagsDatum: null,
  files: [],
}

const Avgangsvederlag = ({
  formName,
  content,
  startDatum,
  sistaDagMedLon,
  handleCancel,
  isExpanded,
  isPending,
  rapporteraAvgangsvederlag,
  setPending,
}: IAvgangsvederlag) => {
  const customEventTracking = useTrackCustomClick()

  const formSubmit = () => {
    const formData = getFormData(formik.values, startDatum)
    rapporteraAvgangsvederlag(formData)
    setPending(true)

    customEventTracking(formName, {
      label: 'skicka',
    })
  }

  const formik = useFormik({
    onSubmit: formSubmit,
    initialValues,
    validationSchema: validationSchema(startDatum),
  })

  const { setSubmitting } = formik

  useEffect(() => {
    setSubmitting(false)
  }, [isPending, setSubmitting])

  useResetForm(isExpanded, formik.resetForm, initialValues)

  const isVisible =
    formik.values.vederlagsDatum &&
    isBefore(new Date(formik.values.vederlagsDatum), new Date(startDatum))

  const isFilesError = Boolean(formik.touched.files && formik.errors.files)

  const uploadContent = fileUploadContent(
    content.attachmentButtonText,
    content.attachmentInformationText
  )

  return (
    <form onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
      <HTMLMapper body={content.informationText} />

      <Grid container spacing={2}>
        <Grid xs={12} sm={6} item>
          <DatePicker
            name="vederlagsDatum"
            value={
              formik.values.vederlagsDatum &&
              new Date(formik.values.vederlagsDatum)
            }
            onChange={customSetFieldValue(
              formik.setFieldValue,
              'vederlagsDatum'
            )}
            label={content.dateInputFieldHeader}
            minDate={
              sistaDagMedLon ? addDays(trrDate(sistaDagMedLon), 1) : undefined
            }
            errorMessage={generateErrorMessage({
              touched: formik.touched,
              selector: 'vederlagsDatum',
              errorMsg: formik.errors.vederlagsDatum ?? '',
            })}
          />
        </Grid>
      </Grid>

      {isVisible && (
        <FileUpload
          isLoadingUpload={false}
          content={uploadContent}
          inputName={'files'}
          files={formik.values.files}
          setFieldValue={formik.setFieldValue}
          errorMessage={isFilesError ? String(formik.errors.files) : ''}
        />
      )}
      <FormSubmit
        submitText={content.buttonText}
        submitAction={formik.handleSubmit}
        cancelAction={handleCancel}
      />
    </form>
  )
}

export default Avgangsvederlag
