import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import HTMLMapper from '@local/src/Components/HTMLMapper'

import SkrivUtBeslut from '../SkrivUtBeslut'

import ErsattningsDagar from './ErsattningsDagar'
import AgeAmount from './AgeAmount'
import ArendeNummer from './ArendeNummer'
import { IMyAge } from './MyAge.types'

const MyAge: React.FunctionComponent<IMyAge> = ({
  agePerioderAggregerat,
  arendeNummer,
  content,
  kanSkrivaUtAgeBeslut,
  maxErsattningsDagar,
  skrivUtBeslut,
  skrivUtBeslutLoading,
  startDatum,
  totalLon,
  utbetaldaDagar,
}) => {
  const hasPeriodWithNoAge = agePerioderAggregerat.some(
    (period) => period.dagPenning === 0
  )

  return (
    <Box mt={4}>
      <Typography variant="h2" gutterBottom>
        {content.heading}
      </Typography>

      <ArendeNummer
        title={content.arendeNummerLabel}
        arendeNummer={arendeNummer}
      />

      <Grid container>
        <Grid sm={6} xs={12} item>
          <ErsattningsDagar
            startDatum={startDatum}
            maxErsattningsDagar={maxErsattningsDagar}
            utbetaldaDagar={utbetaldaDagar}
            content={content}
          />
        </Grid>
        <Grid sm={6} xs={12} item>
          <AgeAmount
            agePerioderAggregerat={agePerioderAggregerat}
            totalLon={totalLon}
            content={content}
            maxDagsBeloppText={content.maxDagsBeloppText}
            beloppHeader={content.beloppHeader}
          />
        </Grid>

        {hasPeriodWithNoAge && (
          <Grid xs={12} item mt={2}>
            <HTMLMapper body={content.informationNollKrPerDagInfoText} />
          </Grid>
        )}

        <Grid xs={12} item mt={2}>
          <SkrivUtBeslut
            disabled={!kanSkrivaUtAgeBeslut}
            content={content.skrivUtBeslut}
            skrivUtBeslut={skrivUtBeslut}
            skrivUtBeslutLoading={skrivUtBeslutLoading}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default MyAge
