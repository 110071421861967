import React from 'react'

export const KeyRoute: React.FunctionComponent<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  React.PropsWithChildren<any>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
> = ({ children }) => children

export const AppShellRouter: React.FunctionComponent<
  React.PropsWithChildren<{
    currentKey: string
  }>
> = ({ currentKey, children }) => {
  let component

  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (child.props.urlKey === currentKey) {
        component = child
      }
    } else {
      component = null
    }
  })

  return component ?? null
}
