import React, { FC } from 'react'
import { Button } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { trrFormat } from '@local/Utils/Helpers/formatDate'
import HTMLMapper from '@local/src/Components/HTMLMapper'

import { AgeHandelseItem } from '../../AgeHandelser/AgeHandelse'
import { AgeStep, AgeStepsIconMap } from '../Fragments/AgeStep'
import { IAktiveraEpiContent } from '../NextStepAge.types'

import useActivatePaymentsData from './useActivatePaymentsData'

export interface IActivatePayments {
  content: IAktiveraEpiContent
}

export const ActivatePayments: FC<IActivatePayments> = ({ content }) => {
  const history = useHistory()

  const {
    startDatum,
    lockForsakraArbetssokande,
    enableStartatEgetForetag,
    enableForsakraArbetssokande,
    lockStartatEgetForetag,
  } = useActivatePaymentsData()

  const gotoUrl = (url: string) => () => {
    history.push(url)
  }

  return (
    <AgeStep
      header={content.utbetalningarHeader}
      icon={AgeStepsIconMap.questionform}
      testSelector="activate-payments"
    >
      <AgeHandelseItem
        content={{
          header: content.confirmJobSeekerHeader,
          description: (
            <HTMLMapper
              body={content.confirmJobSeekerText}
              replaceValues={{
                '{AGEStart}': startDatum && trrFormat(startDatum),
              }}
            />
          ),
          button: (
            <Button
              size="large"
              disabled={!enableForsakraArbetssokande}
              onClick={gotoUrl(content.confirmJobSeekerLink.url)}
            >
              {content.confirmJobSeekerLink.text}
            </Button>
          ),
        }}
        isDisabled={lockForsakraArbetssokande}
      />

      <AgeHandelseItem
        content={{
          header: content.startatEgetForetagHeader,
          description: (
            <HTMLMapper
              body={content.startatEgetForetagText}
              replaceValues={{
                '{AGEStart}': startDatum && trrFormat(startDatum),
              }}
            />
          ),
          button: (
            <Button
              size="large"
              disabled={!enableStartatEgetForetag}
              onClick={gotoUrl(content.startatEgetForetagLink.url)}
            >
              {content.startatEgetForetagLink.text}
            </Button>
          ),
        }}
        isDisabled={lockStartatEgetForetag}
      />
    </AgeStep>
  )
}
