import React from 'react'
import { Button, Grid } from '@mui/material'

interface IFormSubmit {
  submitText?: string
  cancelText?: string
  submitAction: () => void
  cancelAction?: () => void
}

const FormSubmit: React.FunctionComponent<
  React.PropsWithChildren<IFormSubmit>
> = ({
  submitText = 'Skicka',
  cancelText = 'Avbryt',
  submitAction,
  cancelAction,
}) => (
  <Grid container mt={4} spacing={1}>
    <Grid item xs="auto">
      <Button
        size="large"
        onClick={submitAction}
        style={{ width: '140px' }}
        data-trackable="false"
      >
        {submitText}
      </Button>
    </Grid>
    <Grid item xs="auto">
      {cancelAction && (
        <Button
          variant="outlined"
          onClick={cancelAction}
          style={{ width: '140px' }}
          data-trackable="false"
        >
          {cancelText}
        </Button>
      )}
    </Grid>
  </Grid>
)

export default FormSubmit
