import { useEffect } from 'react'

const useResetForm = (isExpanded: boolean, resetForm: () => void): void => {
  useEffect(() => {
    !isExpanded &&
      setTimeout(() => {
        resetForm()
      }, 500)
  }, [isExpanded, resetForm])
}

export default useResetForm
