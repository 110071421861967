import * as React from 'react'
import { FormikProps } from 'formik'
import { setFieldValue as customSetFieldValue } from '@local/Utils/Helpers'
import { trrDate } from '@local/Utils/Helpers/formatDate'
import { addBusinessDays } from 'date-fns'
import { DatePicker } from '@local/src/Components/DatePicker'
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import { isNil } from 'ramda'
import FormErrorMessage from '@local/src/Components/FormErrorMessage'
import HTMLMapper from '@local/src/Components/HTMLMapper'

import MinaInkomstUppgifterForm from '../Common/MinaInkomstUppgifterForm'
import { IMinaInkomstUppgifterFormContent } from '../Common/MinaInkomstUppgifterForm/MinaInkomstUppgifterForm.types'

import {
  IForsakraArbetssokande,
  IForsakraArbetssokandeFormValues,
} from './ForsakraArbetssokande.types'

type IForsakraArbetssokandeForm = Omit<IForsakraArbetssokande, 'isExpanded'>

const ForsakraArbetssokandeForm: React.FunctionComponent<
  IForsakraArbetssokandeForm &
    Partial<FormikProps<IForsakraArbetssokandeFormValues>>
> = ({
  content,
  startDatum,
  handleChange,
  handleBlur,
  values: {
    anstallningsForm,
    arbetssokandeStartDatum,
    avbrottsOrsak,
    avbrottStartDatum,
    inkomstUppgift,
    isAvbrytaArbetssokande,
    isInskrivenSomArbetssokande,
    forsakran,
  },
  setFieldValue,
  touched,
  errors: {
    arbetssokandeStartDatum: arbetssokandeStartDatumError,
    avbrottStartDatum: avbrottStartDatumError,
    avbrottsOrsak: avbrottsOrsakError,
    inkomstUppgift: inkomstUppgiftError,
    forsakran: forsakranError,
    anstallningsForm: anstallningsFormError,
  },
  foretagNamn,
}) => {
  const changeLoneRevisionsDatum = React.useCallback(
    (value: Date) => {
      void setFieldValue('inkomstUppgift.lonerevisionsDatum', value)
    },
    [setFieldValue]
  )

  return (
    <>
      {!inkomstUppgift?.harUppdateratLon && (
        <MinaInkomstUppgifterForm
          handleBlur={handleBlur}
          handleChange={handleChange}
          handleChangeDate={changeLoneRevisionsDatum}
          values={inkomstUppgift}
          content={content.inkomstUppgift as IMinaInkomstUppgifterFormContent}
          foretagNamn={foretagNamn}
          errors={inkomstUppgiftError}
        />
      )}

      <Grid container spacing={2} mt={2}>
        <Grid xs={12} pb={2} item>
          <Typography variant="body1">{content.informationText}</Typography>
        </Grid>

        {!inkomstUppgift?.harUppdateratLon && (
          <Grid xs={12} item>
            <Typography variant="h6" pt={4} pb={4}>
              {content.additionalInformationHeader}
            </Typography>
          </Grid>
        )}

        <Grid xs={12} item>
          <RadioGroup
            defaultValue={isInskrivenSomArbetssokande ? 'true' : 'false'}
            name="isInskrivenSomArbetssokande"
          >
            <Typography variant="body1">
              {content.inskrivenSomArbetssokandeHeader}
            </Typography>

            <FormControlLabel
              value="true"
              control={<Radio />}
              label="Ja"
              data-testid="isInskrivenSomArbetssokande"
              onChange={handleChange}
            />
            <FormControlLabel
              value="false"
              control={<Radio />}
              label="Nej"
              data-testid="notInskrivenSomArbetssokande"
              onChange={handleChange}
            />
          </RadioGroup>
        </Grid>

        <Grid xs={12} sm={6} item>
          {isInskrivenSomArbetssokande === 'true' ? (
            <DatePicker
              name="arbetssokandeStartDatum"
              testId="arbetssokandeStartDatum"
              value={arbetssokandeStartDatum ?? null}
              onChange={customSetFieldValue(
                setFieldValue,
                'arbetssokandeStartDatum'
              )}
              label={content.startDatumText}
              minDate={trrDate(startDatum)}
              maxDate={trrDate()}
              errorMessage={
                touched.arbetssokandeStartDatum &&
                arbetssokandeStartDatumError !== ''
                  ? (arbetssokandeStartDatumError as string)
                  : ''
              }
            />
          ) : (
            <Box
              pt={1}
              pb={2}
              sx={(theme) => ({
                color: theme.palette.error.main,
              })}
            >
              <HTMLMapper
                body={
                  content.inteInskrivenSomArbetssokandeText ??
                  'För att få AGE måste du vara inskriven hos Arbetsförmedlingen.'
                }
              />
            </Box>
          )}
        </Grid>

        <Grid xs={12} item mt={4}>
          <RadioGroup
            defaultValue={isAvbrytaArbetssokande === 'true' ? 'true' : 'false'}
            name="isAvbrytaArbetssokande"
          >
            <Typography variant="body1">
              {content.avbrottArbetssokandeHeader}
            </Typography>

            <FormControlLabel
              value="true"
              control={<Radio />}
              label="Ja"
              onChange={handleChange}
              data-testid="isAvbrytaArbetssokande"
            />
            <FormControlLabel
              value="false"
              control={<Radio />}
              label="Nej"
              data-testid="notAvbrytaArbetssokande"
              onChange={handleChange}
            />
          </RadioGroup>
        </Grid>

        <Grid xs={12} sm={6} item>
          {isAvbrytaArbetssokande === 'true' && (
            <Grid container spacing={2}>
              <Grid xs={12} sm={6} item>
                <DatePicker
                  label={content.avbrottsOrsakFromDatumHeader}
                  value={avbrottStartDatum ?? undefined}
                  name="avbrottStartDatum"
                  errorMessage={
                    touched.avbrottStartDatum && avbrottStartDatumError !== ''
                      ? (avbrottStartDatumError as string)
                      : ''
                  }
                  onChange={customSetFieldValue(
                    setFieldValue,
                    'avbrottStartDatum'
                  )}
                  maxDate={addBusinessDays(arbetssokandeStartDatum ?? 0, 30)}
                  testId="avbrottStartDatum"
                />
              </Grid>

              <Grid xs={12} item />

              <Grid xs={12} sm={6} item>
                <RadioGroup
                  defaultValue={isAvbrytaArbetssokande ? 'true' : 'false'}
                  name="avbrottsOrsak"
                >
                  <Typography variant="body2">
                    {content.orsakBulletPointHeader}
                  </Typography>

                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Ny anställning"
                    data-testid="avbrottsOrsak-2"
                    onChange={handleChange}
                  />
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Pension"
                    data-testid="avbrottsOrsak-1"
                    onChange={handleChange}
                  />
                  <FormControlLabel
                    value="7"
                    control={<Radio />}
                    label="Annat avbrott"
                    data-testid="avbrottsOrsak-7"
                    onChange={handleChange}
                  />

                  <FormHelperText error={Boolean(touched.avbrottsOrsak)}>
                    {avbrottsOrsakError}
                  </FormHelperText>
                </RadioGroup>
              </Grid>

              <Grid xs={12} sm={6} item />
            </Grid>
          )}
        </Grid>

        <Grid xs={12} item />

        <Grid xs={12} sm={6} item>
          {avbrottsOrsak === '2' && (
            <FormControl fullWidth>
              <InputLabel id="anstallningsForm-label">
                Anställningsform:
              </InputLabel>
              <Select
                labelId="anstallningsForm-label"
                label="Anställningsform:"
                name="anstallningsForm"
                value={anstallningsForm}
                onChange={(event: SelectChangeEvent) =>
                  void setFieldValue('anstallningsForm', event.target.value)
                }
              >
                <MenuItem value="1">Tillsvidare</MenuItem>
                <MenuItem value="2">Visstid</MenuItem>
                <MenuItem value="3">Provanställning</MenuItem>
                <MenuItem value="4">Projektanställning</MenuItem>
              </Select>

              {anstallningsFormError?.length > 0 && (
                <FormErrorMessage>{anstallningsFormError}</FormErrorMessage>
              )}
            </FormControl>
          )}
        </Grid>

        <Grid xs={12} sm={6} item />

        <Grid xs={12} item>
          <FormControl
            error={touched.forsakran && !isNil(forsakranError)}
            variant="standard"
            fullWidth
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={forsakran}
                  color="primary"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="forsakran"
                />
              }
              label={content.forsakraArbetssokandeCheckboxText}
            />
            {touched.forsakran && !isNil(forsakranError) && (
              <FormErrorMessage>{forsakranError}</FormErrorMessage>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </>
  )
}

export default ForsakraArbetssokandeForm
