import { mergeDeepLeft, path, pathOr, pick, pipe, values, equals } from 'ramda'

import {
  ageEvents,
  handelseAvailability,
  handelseMap,
  IHandelse,
} from './AgeHandelser/AgeHandelseMap'
import { IAgeData } from './MyAge/MyAge.types'

// Paths
export const age = path<Partial<IAgeData>>(['arende', 'age'])
export const ageLoaded = pipe(path(['arende', 'ageLoaded']))
export const arendeNummer = path(['arende', 'arendeNummer'])
export const dagarTillUtbetalning = path([
  'arende',
  'age',
  'dagarTillUtbetalning',
])
export const isAgePopulated = path(['arende', 'isPopulated'])
export const kanSkrivaUtAgeBeslut = path([
  'arende',
  'age',
  'kanSkrivaUtAgeBeslut',
])
export const skrivUtBeslut = path(['arende', 'skrivUtBeslut'])
export const maxDagsBelopp = path(['arende', 'age', 'maxDagsBelopp'])
export const maxErsattningsDagar = path([
  'arende',
  'age',
  'maxErsattningsDagar',
])
export const sistaDagMedLon = path([
  'arende',
  'inkomstuppgifter',
  'sistaDagMedLon',
])
export const skattesats = path(['arende', 'age', 'skattesats'])
export const skrivUtBeslutLoading = path(['arende', 'skrivUtBeslutLoading'])
export const startDatum = path<string>(['arende', 'age', 'startDatum'])
export const startDatumEfterskydd = path([
  'arende',
  'age',
  'efterskyddStartDatum',
])
export const totalLon = path(['arende', 'inkomstuppgifter', 'totalLon'])
export const utbetaldaDagar = path(['arende', 'age', 'utbetaldaDagar'])
export const datumStartaEget = path([
  'arende',
  'age',
  'startatEgetForetagDatum',
])

export const confirmSubmissionSuccess = path([
  'arende',
  'confirmSubmissionSuccess',
])
export const agePerioderAggregerat = pathOr(
  [],
  ['arende', 'age', 'mojligaAgePerioder']
)
export const kanIntygaArbetssokandeArbetsformedling = path([
  'arende',
  'age',
  'kanIntygaArbetssokandeArbetsformedling',
])
export const kanIntygaArbetssokandeAkassa = path([
  'arende',
  'age',
  'kanIntygaArbetssokandeAkassa',
])

const mergeEventsWithMap = pipe<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any[],
  Record<string, IHandelse>,
  Record<string, IHandelse>,
  IHandelse[]
>(pick(ageEvents), mergeDeepLeft(handelseMap), values)

export const sortedAgeHandelser = pipe(path(['content']), mergeEventsWithMap)

export const klientHandelser = path(['arende', 'age', 'ageActions'])
export const klientHandelserAvailability = pipe(
  klientHandelser,
  pick(handelseAvailability)
)

export const findAvailableCategories: (
  ageCategories: IHandelse[],
  bools: { [key: string]: string }
) => IHandelse[] = (ageCategories, bools: { [key: string]: string }) =>
  ageCategories.filter(
    (event: IHandelse) => bools[event.availability] !== 'None'
  )

export const isPending = path<boolean>(['arende', 'isPending'])

export const isDisabledHandelse = equals('ReadOnly')

export const isReadWrite = equals('ReadWrite')

export const enableForsakraArbetssokande = pipe(
  path(['arende', 'age', 'ageActions', 'forsakraArbetssokande']),
  isReadWrite
)

export const lockForsakraArbetssokande = pipe(
  path(['arende', 'age', 'ageActions', 'forsakraArbetssokande']),
  isDisabledHandelse
)

export const enableStartatEgetForetag = pipe(
  path(['arende', 'age', 'ageActions', 'rapporteraStartatEgetForetag']),
  isReadWrite
)

export const lockStartatEgetForetag = pipe(
  path(['arende', 'age', 'ageActions', 'rapporteraStartatEgetForetag']),
  isDisabledHandelse
)
export const enableAkassaSamtycke = pipe(
  path(['arende', 'age', 'ageActions', 'lamnaAkassaSamtycke']),
  isReadWrite
)

export const lockAkassaSamtycke = pipe(
  path(['arende', 'age', 'ageActions', 'lamnaAkassaSamtycke']),
  isDisabledHandelse
)

export const foretagNamn = path([
  'arende',
  'anstallningsuppgifter',
  'foretag',
  'namn',
])

export const inkomstUppgift = path(['arende', 'inkomstuppgifter'])

export const harInteAkassa = path<boolean>(['arende', 'age', 'harInteAkassa'])
export const villInteAngeAkassa = path<boolean>([
  'arende',
  'age',
  'villInteAngeAkassa',
])

export const harAktiveratAgeEjAvslutad = path<boolean>([
  'arende',
  'age',
  'harAktiveratAgeEjAvslutad',
])

export const harAvslutadAge = path<boolean>(['arende', 'age', 'harAvslutadAge'])

export const harAgeEjStartad = path<boolean>([
  'arende',
  'age',
  'harAgeEjStartad',
])

export const arendeUpdated = path(['arende', 'arendeUpdated'])
export const formError = path(['arende', 'formError'])

export const klientId = path<string>(['arende', 'klientId'])
