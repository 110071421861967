import React, { FC } from 'react'
import Locked from '@local/Components/Locked'
import { Box, Grid, Typography } from '@mui/material'

import { IAgeHandelseItem } from '../AgeHandelser.types'

const AgeHandelseItem: FC<React.PropsWithChildren<IAgeHandelseItem>> = ({
  content: { header, description, button },
  isDisabled,
}) => (
  <Grid
    container
    sx={(theme) => ({
      border: isDisabled ? `1px solid ${theme.palette.divider}` : 'none',
      borderRadius: theme.spacing(0.5),
      mb: 4,
      mt: 3,
    })}
    spacing={isDisabled ? 2 : 0}
    padding={isDisabled ? 2 : 0}
  >
    <Grid xs={isDisabled ? 8 : 12} item>
      <Box
        sx={{
          flexGrow: 1,
          flexBasis: '100%',
        }}
      >
        {header && <Typography variant="h6">{header}</Typography>}
        {description && (
          <Typography variant="body2" mb={0}>
            {description}
          </Typography>
        )}
      </Box>
    </Grid>
    <Grid xs={isDisabled ? 4 : 12} item>
      {isDisabled && <Locked />}
      {!isDisabled && <Box>{button}</Box>}
    </Grid>
  </Grid>
)

export default AgeHandelseItem
