import React, { FC } from 'react'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Box } from '@mui/material'

import s from './ExternalLink.module.scss'

interface IExternalLink {
  href: string
  text: string
}

export const ExternalLink: FC<IExternalLink> = ({
  href,
  text,
}: IExternalLink) => (
  <Box mt={3} mb={4}>
    <div className={s.Container}>
      <OpenInNewIcon style={{ height: '22px' }} color="primary" />

      <a href={href} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    </div>
  </Box>
)
