import { useFormikContext } from 'formik'

import {
  convertFodelsedatumToDate,
  getAgeMaxAlder,
} from '../BeraknaAge/BeraknaAge.helpers'
import { IRaknesnurraFormValues } from '../Raknesnurra.types'

export const useAgeMaxAlder = () => {
  const { values } = useFormikContext<IRaknesnurraFormValues>()
  const birthdate = convertFodelsedatumToDate(
    values?.fodelsedatum?.toString() || ''
  )
  const result = getAgeMaxAlder(birthdate)

  return result
}
