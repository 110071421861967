import React from 'react'
import {
  IAkasseBeloppPeriod,
  IBeraknadAge,
  IDagpenningAkassaPeriod,
  IInkomstbasbeloppPeriod,
} from '@local/Raknesnurra/BeraknaAge/BeraknaAge.types'
import { IRaknesnurraFormContent } from '@local/Raknesnurra/Raknesnurra.types'
import FadeIn from '@local/Components/FadeIn'
import { Box, Typography } from '@mui/material'
import { formatCurrencySEK } from '@local/src/Utils/Helpers'

import { getTotalBelopp } from '../BeraknaAge/BeraknaAge.helpers'

import { useResult } from './useResult'
import { AgePeriod } from './AgePeriod'
import { SaknarAgeRattigheter } from './SaknarAgeRattigheter'
import { useRetirementAge } from './getRetirementAge'

export const Result = ({
  content,
  dagPenningAkassaPerioder,
  inkomstbasbeloppPerioder,
  akasseBeloppPerioder,
  reRenderFlag = false,
}: {
  content: IRaknesnurraFormContent
  dagPenningAkassaPerioder: IDagpenningAkassaPeriod[]
  inkomstbasbeloppPerioder: IInkomstbasbeloppPeriod[]
  akasseBeloppPerioder: IAkasseBeloppPeriod[]
  reRenderFlag?: boolean
}): JSX.Element => {
  const {
    hasTillatenLon,
    is40EllerAldre,
    isYngreAnAgeMaxAlder,
    showResult,
    agePeriods,
    isReducedDaysDueToPension,
  } = useResult(
    dagPenningAkassaPerioder,
    inkomstbasbeloppPerioder,
    akasseBeloppPerioder
  )

  const allowedAge = hasTillatenLon && is40EllerAldre && isYngreAnAgeMaxAlder
  const noAge = allowedAge && agePeriods?.length === 0
  const hasAge = allowedAge && agePeriods?.length > 0
  const retirementAge = useRetirementAge()
  const contentMaxAge = content.aldreAn65.replace(
    '65',
    retirementAge.toString()
  )

  const sum = getTotalBelopp(agePeriods)

  return showResult && !reRenderFlag ? (
    <Box
      data-testid="result"
      mt={4}
      sx={(theme) => ({
        borderTop: `1px solid ${theme.palette.divider}`,
      })}
    >
      <FadeIn>
        <h4>{content.rubrikBerakning}</h4>

        {isReducedDaysDueToPension && (
          <Typography variant="body2" marginBottom={2}>
            {contentMaxAge}
          </Typography>
        )}

        {hasAge &&
          agePeriods?.length > 0 &&
          Object.values(agePeriods)?.map((beraknadAge: IBeraknadAge) => (
            <AgePeriod
              key={beraknadAge.startDay}
              agePeriod={beraknadAge}
              content={content}
            />
          ))}

        {hasAge && (
          <>
            <Typography variant="body1" fontWeight="bold">
              Summa:
            </Typography>
            <Typography variant="body2">{formatCurrencySEK(sum)}</Typography>
          </>
        )}

        {noAge && <Typography variant="body2">Ingen AGE</Typography>}

        {!allowedAge && (
          <SaknarAgeRattigheter
            hasTillatenLon={hasTillatenLon}
            is40EllerAldre={is40EllerAldre}
            isYngreAnAgeMaxAlder={isYngreAnAgeMaxAlder}
            ejTillatenLonInformation={content.ejTillatenLon}
            yngreAn40Information={content.yngreAn40}
            aldreAn65Information={contentMaxAge}
            ejRattTillAgeInformation={content.ejRattTillAgeInformation}
          />
        )}
      </FadeIn>
    </Box>
  ) : null
}
