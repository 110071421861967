import React, { FC } from 'react'
import * as Yup from 'yup'
import { withFormik, FormikProps, Form } from 'formik'
import HTMLMapper from '@local/src/Components/HTMLMapper'
import FileUpload from '@local/Components/FileUpload'
import EpiFile from '@local/Components/EpiFile'
import {
  encodeRFC5987ValueChars,
  trimPathInformation,
} from '@local/Utils/Helpers/fileHelpers'
import { isNil } from 'ramda'
import { fileUploadContent } from '@local/src/Components/FileUpload/fileUpload.helpers'
import { IUniqueFile } from '@local/src/Components/FileUpload/FileUpload.types'
import { Box } from '@mui/material'
import useResetForm from '@local/src/Utils/Hooks/useResetForm'

import FormSubmit from '../../../Components/FormSubmit'

import { ILonegaranti, ILonegarantiFormValues } from './Lonegaranti.types'

export const Lonegaranti: FC<
  React.PropsWithChildren<ILonegaranti & FormikProps<ILonegarantiFormValues>>
> = ({
  content,
  handleSubmit,
  touched,
  setFieldValue,
  values: { files },
  errors: { files: filesError },
  handleCancel,
  resetForm,
  isExpanded,
}) => {
  useResetForm(isExpanded, resetForm)

  const uploadContent = fileUploadContent(
    content.attachmentButtonText,
    content.attachmentLabel
  )

  const isFilesError = Boolean(touched.files && filesError)

  return (
    <Form>
      <Box paddingTop={1} paddingBottom={2}>
        <HTMLMapper body={content.informationText} />
      </Box>

      <EpiFile relLink={content.exampleFile} label={content.exampleFileLabel} />
      <FileUpload
        isLoadingUpload={false}
        content={uploadContent}
        inputName={'files'}
        files={files}
        setFieldValue={setFieldValue}
        errorMessage={isFilesError ? String(filesError) : ''}
      />
      <FormSubmit
        submitText={content.buttonText}
        submitAction={handleSubmit}
        cancelAction={handleCancel}
      />
    </Form>
  )
}

export default withFormik<ILonegaranti, ILonegarantiFormValues>({
  mapPropsToValues: () => ({
    files: [],
  }),
  validationSchema: () =>
    Yup.lazy(() =>
      Yup.object().shape({
        files: Yup.mixed().test(
          'files',
          'Du måste bifoga lönegarantibeslut',
          (files: IUniqueFile[]) => !isNil(files[0])
        ),
      })
    ),
  handleSubmit: (
    values,
    { props: { lonegarantibeslut, setPending }, setSubmitting, resetForm }
  ) => {
    const formData = new FormData()
    const file = values.files[0]

    if (file) {
      const trimmedFileName = trimPathInformation(file.name)
      formData.append(
        'file',
        file.fileStream,
        encodeRFC5987ValueChars(trimmedFileName)
      )
    }
    lonegarantibeslut(formData)
    setSubmitting(false)
    setPending(true)

    setTimeout(() => {
      resetForm()
    }, 2000)
  },
  displayName: 'Lonegaranti',
})(Lonegaranti)
