/* eslint-disable @typescript-eslint/no-unsafe-return */
import { AnyAction } from 'redux'
import { handleActions } from 'redux-actions'

import {
  getAgeInitiate,
  getAgeSuccess,
  getAgeFailure,
  cleanAge,
  cleanSkrivUtBeslut,
  closeConfirmation,
  atertagenUppsagningInitiate,
  atertagenUppsagningSuccess,
  atertagenUppsagningFailure,
  skatteavdragInitiate,
  skatteavdragSuccess,
  skatteavdragFailure,
  rapporteraAvgangsvederlagInitiate,
  rapporteraAvgangsvederlagSuccess,
  rapporteraAvgangsvederlagFailure,
  postRapporteraStartaEgetForetagInitiate,
  postRapporteraStartaEgetForetagSuccess,
  postRapporteraStartaEgetForetagFailure,
  forsakraArbetssokandeSuccess,
  forsakraArbetssokandeFailure,
  rapporteraLonegarantiInitiate,
  rapporteraLonegarantiSuccess,
  rapporteraLonegarantiFailure,
  intygaAktivitetstodInitiate,
  intygaAktivitetstodSuccess,
  intygaAktivitetstodFailure,
  intygaArbetssokandeAktivitetsstodInitiate,
  intygaArbetssokandeAktivitetsstodSuccess,
  intygaArbetssokandeAktivitetsstodFailure,
  getSkrivUtBeslutInitiate,
  getSkrivUtBeslutSuccess,
  getSkrivUtBeslutFailure,
  rapporteraArbetssokandeAvbrottInitiate,
  rapporteraArbetssokandeAvbrottSuccess,
  rapporteraArbetssokandeAvbrottFailure,
  rapporteraAteroppnatArendeInitiate,
  rapporteraAteroppnatArendeSuccess,
  rapporteraAteroppnatArendeFailure,
  intygaArbetssokandeInitiate,
  intygaArbetssokandeSuccess,
  intygaArbetssokandeFailure,
  setPending,
  clearFormError,
} from './Age.actions'
import { IAgeData } from './MyAge/MyAge.types'
import { IDataSkrivUtBeslut } from './SkrivUtBeslut/SkrivUtBeslut.types'

const skrivUtBeslut: IDataSkrivUtBeslut = {
  isPopulated: false,
  agePerioderSkrivUt: [],
  firstName: undefined,
  lastName: undefined,
  maxErsattningsDagar: undefined,
  maxDagsBeloppText: undefined,
  socialSecurityNumber: undefined,
  startDatum: undefined,
  totalLon: undefined,
}

export interface IAgeState {
  age: Partial<IAgeData>
  skrivUtBeslut: IDataSkrivUtBeslut
  noReload: boolean
  ageLoaded: boolean
  confirmSubmissionSuccess: boolean
  arendeUpdated: boolean
  formError: boolean
  isPending: boolean
}

export const initialState: IAgeState = {
  isPending: false,
  arendeUpdated: false,
  ageLoaded: false,
  confirmSubmissionSuccess: false,
  formError: false,
  noReload: false,
  skrivUtBeslut: {
    isPopulated: false,
    agePerioderSkrivUt: [],
    firstName: '',
    lastName: '',
    maxErsattningsDagar: 0,
    maxDagsBeloppText: '',
    socialSecurityNumber: undefined,
    startDatum: undefined,
    totalLon: undefined,
  },
  age: {
    ageActions: {
      andraRapporteratAvgangsvederlag: '',
      andraSkatteAvdrag: '',
      forsakraArbetssokande: '',
      intygaAktivitetsstod: '',
      intygaArbetssokande: '',
      lamnaAkassaSamtycke: '',
      rapporteraArbetssokandeAvbrott: '',
      rapporteraAteroppnatArende: '',
      rapporteraAtertagenUppsagning: '',
      rapporteraLonegaranti: '',
      rapporteraNyttAvgangsvederlag: '',
      rapporteraStartatEgetForetag: '',
      intygaArbetssokandeAktivitetsstod: '',
      rapporteraAvslutatEgetForetag: '',
    },
    kanSkrivaUtAgeBeslut: false,
  },
}

interface AgePayload {
  noReload?: boolean
}

const arendeUpdated = 'arendeUpdated'

const ageReducer = handleActions<IAgeState, AgePayload | null>(
  {
    [getAgeInitiate.toString()]: (state, action) => ({
      ...state,
      ageLoaded: action.payload.noReload,
    }),
    [getAgeSuccess.toString()]: (state, action) => ({
      ...state,
      ...action.payload,
      ageLoaded: true,
      confirmSubmissionSuccess: false,
      arendeUpdated: false,
      isPending: false,
    }),
    [getAgeFailure.toString()]: (state) => ({
      ...state,
      ageLoaded: false,
      confirmSubmissionSuccess: false,
      arendeUpdated: false,
    }),
    [atertagenUppsagningInitiate.toString()]: (state) => ({
      ...state,
      formError: false,
    }),
    [atertagenUppsagningSuccess.toString()]: (state) => ({
      ...state,
      confirmSubmissionSuccess: true,
    }),
    [atertagenUppsagningFailure.toString()]: (state) => ({
      ...state,
      formError: true,
    }),
    [skatteavdragInitiate.toString()]: (state) => ({
      ...state,
      formError: false,
    }),
    [skatteavdragSuccess.toString()]: (state) => ({
      ...state,
    }),
    [skatteavdragFailure.toString()]: (state) => ({
      ...state,
      formError: true,
    }),
    [postRapporteraStartaEgetForetagInitiate.toString()]: (state) => ({
      ...state,
      formError: false,
    }),
    [postRapporteraStartaEgetForetagSuccess.toString()]: (state) => ({
      ...state,
      confirmSubmissionSuccess: true,
    }),
    [postRapporteraStartaEgetForetagFailure.toString()]: (state) => ({
      ...state,
      formError: true,
    }),
    [rapporteraAvgangsvederlagInitiate.toString()]: (state) => ({
      ...state,
      formError: false,
    }),
    [rapporteraAvgangsvederlagSuccess.toString()]: (state) => ({
      ...state,
      confirmSubmissionSuccess: true,
    }),
    [rapporteraAvgangsvederlagFailure.toString()]: (state) => ({
      ...state,
      formError: true,
    }),
    [forsakraArbetssokandeSuccess.toString()]: (state) => ({
      ...state,
      confirmSubmissionSuccess: true,
    }),
    [forsakraArbetssokandeFailure.toString()]: (state) => ({
      ...state,
      formError: true,
    }),
    [rapporteraLonegarantiInitiate.toString()]: (state) => ({
      ...state,
      formError: false,
    }),
    [rapporteraLonegarantiSuccess.toString()]: (state) => ({
      ...state,
      confirmSubmissionSuccess: true,
    }),
    [rapporteraLonegarantiFailure.toString()]: (state) => ({
      ...state,
      formError: true,
    }),
    [intygaAktivitetstodInitiate.toString()]: (state) => ({
      ...state,
      formError: false,
    }),
    [intygaAktivitetstodSuccess.toString()]: (state) => ({
      ...state,
      confirmSubmissionSuccess: true,
    }),
    [intygaAktivitetstodFailure.toString()]: (state) => ({
      ...state,
      formError: true,
    }),
    [intygaArbetssokandeAktivitetsstodInitiate.toString()]: (state) => ({
      ...state,
      formError: false,
    }),
    [intygaArbetssokandeAktivitetsstodSuccess.toString()]: (state) => ({
      ...state,
      confirmSubmissionSuccess: true,
      intervalRunning: true,
    }),
    [intygaArbetssokandeAktivitetsstodFailure.toString()]: (state) => ({
      ...state,
      formError: true,
    }),
    [rapporteraArbetssokandeAvbrottInitiate.toString()]: (state) => ({
      ...state,
      formError: false,
    }),
    [rapporteraArbetssokandeAvbrottSuccess.toString()]: (state) => ({
      ...state,
      confirmSubmissionSuccess: true,
    }),
    [rapporteraArbetssokandeAvbrottFailure.toString()]: (state) => ({
      ...state,
      formError: true,
    }),
    [rapporteraAteroppnatArendeInitiate.toString()]: (state) => ({
      ...state,
      formError: false,
    }),
    [rapporteraAteroppnatArendeSuccess.toString()]: (state) => ({
      ...state,
      confirmSubmissionSuccess: true,
    }),
    [rapporteraAteroppnatArendeFailure.toString()]: (state) => ({
      ...state,
      formError: true,
    }),
    [intygaArbetssokandeInitiate.toString()]: (state) => ({
      ...state,
      formError: false,
    }),
    [intygaArbetssokandeSuccess.toString()]: (state) => ({
      ...state,
      confirmSubmissionSuccess: true,
    }),
    [intygaArbetssokandeFailure.toString()]: (state) => ({
      ...state,
      formError: true,
    }),
    [getSkrivUtBeslutInitiate.toString()]: (state) => ({
      ...state,
      skrivUtBeslutLoading: true,
    }),
    [getSkrivUtBeslutSuccess.toString()]: (state, action: AnyAction) => ({
      ...state,
      skrivUtBeslut: {
        ...action.payload,
      },
      skrivUtBeslutLoading: false,
    }),
    [getSkrivUtBeslutFailure.toString()]: (state) => ({
      ...state,
      skrivUtBeslutLoading: false,
    }),
    [cleanAge.toString()]: (state) => ({
      ...initialState,
      skrivUtBeslut: state.skrivUtBeslut,
    }),
    [cleanSkrivUtBeslut.toString()]: (state) => ({
      ...state,
      skrivUtBeslut,
    }),
    [closeConfirmation.toString()]: (state) => ({
      ...state,
      confirmSubmissionSuccess: false,
    }),
    [arendeUpdated]: (state) => ({
      ...state,
      arendeUpdated: true,
    }),
    [setPending.toString()]: (state) => ({
      ...state,
      isPending: true,
    }),
    [clearFormError.toString()]: (state) => ({
      ...state,
      formError: false,
    }),
  },
  initialState
)

export default ageReducer
