import React, { FC } from 'react'
import * as Yup from 'yup'
import { withFormik, FormikProps, Form } from 'formik'
import FileUpload from '@local/Components/FileUpload'
import {
  encodeRFC5987ValueChars,
  trimPathInformation,
} from '@local/Utils/Helpers/fileHelpers'
import EpiFile from '@local/Components/EpiFile'
import { generateErrorMessage } from '@local/Utils/Helpers/Forms/Forms.helpers'
import { isEmpty, isNil, omit } from 'ramda'
import {
  Box,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from '@mui/material'
import { fileUploadContent } from '@local/src/Components/FileUpload/fileUpload.helpers'
import HTMLMapper from '@local/src/Components/HTMLMapper'
import { IUniqueFile } from '@local/src/Components/FileUpload/FileUpload.types'
import useResetForm from '@local/src/Utils/Hooks/useResetForm'

import FormSubmit from '../../../Components/FormSubmit'

import {
  IIntygaArbetssokande,
  IIntygaArbetssokandeFormValues,
} from './IntygaArbetssokande.types'
import { getLabels } from './IntygaArbetssokande.helper'

const IntygaArbetssokande: FC<
  React.PropsWithChildren<
    IIntygaArbetssokande & FormikProps<IIntygaArbetssokandeFormValues>
  >
> = ({
  content,
  handleChange,
  handleSubmit,
  resetForm,
  errors: {
    files: errorFiles,
    arbetssokandeIntygTyp: errorArbetssokandeIntygTyp,
  },
  dagarTillUtbetalning,
  values: { files, arbetssokandeIntygTyp },
  setFieldValue,
  touched,
  handleCancel,
  isExpanded,
}) => {
  useResetForm(isExpanded, resetForm)

  const labels = getLabels(arbetssokandeIntygTyp, content)

  const replaceValues = {
    '{DagarTillUtbetalning}': dagarTillUtbetalning,
  }

  const handleArbetssokandeIntygTypChange = React.useCallback(
    (e: React.ChangeEvent<HTMLElement>) => {
      handleChange(e)
      void setFieldValue('file', null)
    },
    [handleChange, setFieldValue]
  )

  const isFileUploadVisible = !isEmpty(arbetssokandeIntygTyp)
  const arbetssokandeIntygTypError = generateErrorMessage({
    selector: 'arbetssokandeIntygTyp',
    errorMsg: errorArbetssokandeIntygTyp ?? '',
    touched,
  })
  const uploadContent = fileUploadContent(
    content.attachmentButtonText,
    labels.attachmentLabel
  )

  return (
    <Form>
      <Box>
        <HTMLMapper
          body={labels.informationText}
          replaceValues={replaceValues}
        />
      </Box>

      <RadioGroup
        name="arbetssokandeIntygTyp"
        defaultValue={arbetssokandeIntygTyp}
      >
        <FormControlLabel
          value="intyg-arbetsformedling"
          control={<Radio />}
          label="Intyg från arbetsförmedling"
          data-testid="intyg-arbetsformedling"
          onChange={handleArbetssokandeIntygTypChange}
        />
        <FormControlLabel
          value="specifikation-om-ersattning"
          control={<Radio />}
          data-testid="specifikation-om-ersattning"
          label="Specifikation om ersättning från a-kassa"
          onChange={handleArbetssokandeIntygTypChange}
        />

        <FormHelperText error={Boolean(arbetssokandeIntygTypError)}>
          {arbetssokandeIntygTypError}
        </FormHelperText>
      </RadioGroup>

      {isFileUploadVisible && (
        <>
          <EpiFile
            relLink={labels.exampleFile}
            label={labels.exampleFileLabel}
          />

          <FileUpload
            isLoadingUpload={false}
            content={uploadContent}
            inputName={'files'}
            files={files}
            setFieldValue={setFieldValue}
            errorMessage={touched.files && errorFiles ? String(errorFiles) : ''}
            testSelector="file-upload"
          />
        </>
      )}
      <FormSubmit
        submitText={content.buttonText}
        submitAction={handleSubmit}
        cancelAction={handleCancel}
      />
    </Form>
  )
}
export default withFormik<IIntygaArbetssokande, IIntygaArbetssokandeFormValues>(
  {
    mapPropsToValues: () => ({
      files: [],
      arbetssokandeIntygTyp: 'intyg-arbetsformedling',
    }),
    validationSchema: () =>
      Yup.lazy(() =>
        Yup.object().shape({
          files: Yup.mixed().test(
            'files',
            'Du måste bifoga en fil',
            (files: IUniqueFile[]) => !isNil(files[0])
          ),
          arbetssokandeIntygTyp: Yup.string().required(
            'Du behöver välja ett alternativ'
          ),
        })
      ),
    handleSubmit: (
      values,
      {
        props: { intygaArbetssokande, setPending },
        setSubmitting,
        setFieldValue,
        setTouched,
      }
    ) => {
      const formData = new FormData()

      const json = JSON.stringify(omit(['file'], values))
      formData.append('data', json)
      const file = values.files[0]

      if (file) {
        const trimmedFileName = trimPathInformation(file.name)
        formData.append(
          'file',
          file.fileStream,
          encodeRFC5987ValueChars(trimmedFileName)
        )
      }
      intygaArbetssokande(formData)
      setSubmitting(false)
      void setTouched({})
      void setFieldValue('file', null)
      setPending(true)
    },
    displayName: 'IntygaArbetssokande',
  }
)(IntygaArbetssokande)
