// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DP1nNUcMNeiV7XCoC6mt{text-decoration:none;color:unset}.DP1nNUcMNeiV7XCoC6mt:hover{cursor:pointer;text-decoration:underline}`, "",{"version":3,"sources":["webpack://./src/Components/EpiFile/EpiFile.module.scss"],"names":[],"mappings":"AAAA,sBACC,oBAAA,CACA,WAAA,CAEA,4BACC,cAAA,CACA,yBAAA","sourcesContent":[".EpiLink {\n\ttext-decoration: none;\n\tcolor: unset;\n\n\t&:hover {\n\t\tcursor: pointer;\n\t\ttext-decoration: underline;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"EpiLink": `DP1nNUcMNeiV7XCoC6mt`
};
export default ___CSS_LOADER_EXPORT___;
