import * as React from 'react'
import * as Yup from 'yup'
import { FormikProps, withFormik, Form } from 'formik'
import { omit } from 'ramda'
import FileUpload from '@local/Components/FileUpload'
import {
  validateOrganizationNumber,
  setFieldValue as customSetFieldValue,
} from '@local/Utils/Helpers'
import { trrDate } from '@local/Utils/Helpers/formatDate'
import {
  encodeRFC5987ValueChars,
  trimPathInformation,
} from '@local/Utils/Helpers/fileHelpers'
import { personnummer } from '@local/Utils/Helpers/regexes'
import { DatePicker } from '@local/src/Components/DatePicker'
import {
  Box,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material'
import Spinner from '@local/Components/Spinner'
import { fileUploadContent } from '@local/src/Components/FileUpload/fileUpload.helpers'
import HTMLMapper from '@local/src/Components/HTMLMapper'
import { IUniqueFile } from '@local/src/Components/FileUpload/FileUpload.types'
import useResetForm from '@local/src/Utils/Hooks/useResetForm'

import { inkomstUppgiftSchema } from '../Common/MinaInkomstUppgifterForm'
import FormSubmit from '../../../Components/FormSubmit'
import MinaInkomstUppgifterForm from '../Common/MinaInkomstUppgifterForm/MinaInkomstUppgifterForm'

import {
  IStartatEgetForetag,
  IStartatEgetForetagFormValues,
} from './StartatEgetForetag.types'

export const StartatEgetForetag: React.FunctionComponent<
  React.PropsWithChildren<
    IStartatEgetForetag & FormikProps<IStartatEgetForetagFormValues>
  >
> = ({
  content,
  handleChange,
  handleSubmit,
  setFieldValue,
  handleBlur,
  touched,
  handleCancel,
  foretagNamn,
  values: {
    files,
    arbetssokandeIntygTyp,
    foretagsnamn,
    organisationsNummer,
    agarandel,
    startDatum,
    harVaritAktivtArbetssokande,
    inkomstUppgift,
  },
  errors: {
    arbetssokandeIntygTyp: errorArbetssokandeIntygTyp,
    files: errorFiles,
    harVaritAktivtArbetssokande: errorHarVaritAktivtArbetssokande,
    inkomstUppgift: errorInkomstUppgift,
    organisationsNummer: errorOrganisationsNummer,
    agarandel: errorAgarandel,
    startDatum: errorStartDatum,
    foretagsnamn: errorForetagsnamn,
  },
  resetForm,
  isExpanded,
}) => {
  useResetForm(isExpanded, resetForm)

  const handleAktivArbetssokandeOptionsChange = React.useCallback(
    (e: React.ChangeEvent<HTMLElement>) => {
      handleChange(e)
      void setFieldValue('files', [])
    },
    [handleChange, setFieldValue]
  )

  if (!content) return <Spinner size={24} />

  const fileUpload = (text: string) => {
    const uploadContent = fileUploadContent(text, content.attachmentButtonText)

    return (
      <FileUpload
        isLoadingUpload={false}
        content={uploadContent}
        inputName={'files'}
        files={files}
        setFieldValue={setFieldValue}
        errorMessage={touched.files && errorFiles ? String(errorFiles) : ''}
      />
    )
  }

  const renderArbetssokandeIntygTyp = (type: string) => {
    switch (type) {
      case 'intyg-fran-arbetsformedling':
        return <div>{fileUpload('Intyg från arbetsförmedling. ')}</div>
      case 'specifikation-om-ersattning':
        return <div>{fileUpload('Specifikation om ersättning. ')}</div>
      case 'beslut-om-anvisning':
        return <div>{fileUpload('Beslut om anvisning. ')}</div>
      default:
        return null
    }
  }

  const renderRadioButtons = (display: boolean) =>
    display ? (
      <div data-testid="harVaritAktivtArbetssokande-buttons">
        <Typography variant="body1">
          {content.verifyArbetssokandeText}
        </Typography>

        <RadioGroup name="arbetssokandeIntygTyp" value={arbetssokandeIntygTyp}>
          <FormControlLabel
            label="Jag har lämnat samtycke"
            value="a-kassa-samtycke"
            data-testid="a-kassa-samtycke"
            onChange={handleAktivArbetssokandeOptionsChange}
            control={<Radio />}
          />

          <FormControlLabel
            control={<Radio />}
            label="Intyg från arbetsförmedling"
            onChange={handleAktivArbetssokandeOptionsChange}
            value="intyg-fran-arbetsformedling"
            data-testid="intyg-fran-arbetsformedling"
          />

          <FormControlLabel
            control={<Radio />}
            label="Beslut om anvisning (aktivitetsstöd)"
            value="beslut-om-anvisning"
            data-testid="beslut-om-anvisning"
            onChange={handleAktivArbetssokandeOptionsChange}
          />

          <FormControlLabel
            control={<Radio />}
            label="Specifikation om ersättning"
            onChange={handleAktivArbetssokandeOptionsChange}
            value="specifikation-om-ersattning"
            data-testid="specifikation-om-ersattning"
          />

          <FormHelperText
            error={Boolean(
              touched.arbetssokandeIntygTyp && errorArbetssokandeIntygTyp
            )}
          >
            {errorArbetssokandeIntygTyp}
          </FormHelperText>
        </RadioGroup>

        {renderArbetssokandeIntygTyp(arbetssokandeIntygTyp)}
      </div>
    ) : null

  return (
    <Form>
      <Box paddingTop={1}>
        <HTMLMapper body={content.informationText} />
      </Box>

      {!inkomstUppgift.harUppdateratLon && (
        <MinaInkomstUppgifterForm
          handleChange={handleChange}
          values={inkomstUppgift}
          handleChangeDate={customSetFieldValue(
            setFieldValue,
            'inkomstUppgift.lonerevisionsDatum'
          )}
          content={content.inkomstUppgift}
          foretagNamn={foretagNamn}
          errors={errorInkomstUppgift}
          handleBlur={handleBlur}
        />
      )}

      <Grid container spacing={2}>
        <Grid xs={12} item>
          <Typography variant="h6" pt={4} pb={4}>
            {content.foretagsUppgifterHeader}
          </Typography>
          <Typography variant="body1">
            {content.foretagsUppgifterMainBody}
          </Typography>
        </Grid>

        <Grid xs={12} sm={6} item>
          <TextField
            fullWidth
            error={Boolean(
              touched.organisationsNummer && errorOrganisationsNummer
            )}
            helperText={touched.organisationsNummer && errorOrganisationsNummer}
            label={content.organisationNumberInputFieldHeader}
            onChange={handleChange}
            name="organisationsNummer"
            onBlur={handleBlur}
            value={organisationsNummer}
          />
        </Grid>

        <Grid item xs={6} />

        <Grid xs={12} sm={6} item>
          <TextField
            fullWidth
            error={Boolean(touched.foretagsnamn && errorForetagsnamn)}
            helperText={touched.foretagsnamn && errorForetagsnamn}
            label={content.foretagsnamnInputFieldHeader}
            onChange={handleChange}
            name="foretagsnamn"
            onBlur={handleBlur}
            value={foretagsnamn}
          />
        </Grid>

        <Grid item xs={6} />

        <Grid xs={12} sm={6} item>
          <TextField
            fullWidth
            error={Boolean(touched.agarandel && errorAgarandel)}
            helperText={touched.agarandel && errorAgarandel}
            label={content.agarandelInputFieldHeader}
            onChange={handleChange}
            name="agarandel"
            onBlur={handleBlur}
            value={agarandel}
          />
        </Grid>

        <Grid item xs={6} />

        <Grid xs={12} sm={6} item>
          <DatePicker
            label={content.startDatumInputFieldHeader}
            value={startDatum}
            maxDate={trrDate()}
            name="startDatum"
            errorMessage={
              touched.startDatum && errorStartDatum
                ? (errorStartDatum as string)
                : ''
            }
            onChange={customSetFieldValue(setFieldValue, 'startDatum')}
          />
        </Grid>

        <Grid item xs={6} />

        <Grid xs={12} item mt={4}>
          <Typography variant="body1">
            Har du varit aktivt arbetssökande eller deltagit i en aktivitet via
            Arbetsförmedlingen innan du startade ditt företag?
          </Typography>

          <RadioGroup
            name="harVaritAktivtArbetssokande"
            value={harVaritAktivtArbetssokande}
          >
            <FormControlLabel
              label="Ja"
              value="true"
              data-testid="harVaritAktivtArbetssokande"
              onChange={handleChange}
              control={<Radio />}
            />

            <FormControlLabel
              label="Nej"
              value="false"
              data-testid="notVaritAktivtArbetssokande"
              onChange={handleChange}
              control={<Radio />}
            />

            <FormHelperText
              error={Boolean(
                touched.harVaritAktivtArbetssokande &&
                  errorHarVaritAktivtArbetssokande
              )}
            >
              {errorHarVaritAktivtArbetssokande}
            </FormHelperText>
          </RadioGroup>
        </Grid>

        <Grid xs={12} sm={6} item mt={4}>
          {renderRadioButtons(harVaritAktivtArbetssokande === 'true')}
        </Grid>
      </Grid>

      <FormSubmit
        submitText={content.buttonText}
        submitAction={handleSubmit}
        cancelAction={handleCancel}
      />
    </Form>
  )
}

export default withFormik<IStartatEgetForetag, IStartatEgetForetagFormValues>({
  mapPropsToValues: ({ inkomstUppgift }) => ({
    organisationsNummer: '',
    foretagsnamn: '',
    startDatum: null,
    agarandel: '',
    harVaritAktivtArbetssokande: '',
    inkomstUppgift,
    files: [],
    arbetssokandeIntygTyp: '',
  }),
  validationSchema: ({ content, inkomstUppgift }: IStartatEgetForetag) =>
    Yup.object().shape({
      agarandel: Yup.mixed().test(
        'agarandel',
        content.errorAgarandel,
        (value) => value !== null && value >= 33.3 && value <= 100
      ),
      arbetssokandeIntygTyp: Yup.string().when(
        ['harVaritAktivtArbetssokande'],
        {
          is: 'true',
          then: (value: Yup.StringSchema) =>
            value.required('Du behöver välja ett alternativ'),
        }
      ),

      files: Yup.array().when(
        ['harVaritAktivtArbetssokande', 'arbetssokandeIntygTyp'],
        {
          is: (harVaritAktivtArbetssokande, arbetssokandeIntygTyp) =>
            harVaritAktivtArbetssokande === 'true' &&
            arbetssokandeIntygTyp !== 'a-kassa-samtycke',
          then: (value: Yup.ArraySchema<IUniqueFile>) =>
            value.required('Du behöver bifoga en fil'),
        }
      ),
      foretagsnamn: Yup.string().required('Fyll i ett företagsnamn'),
      harVaritAktivtArbetssokande: Yup.string().required(
        'Du behöver välja ett alternativ'
      ),
      inkomstUppgift: inkomstUppgiftSchema(inkomstUppgift.harUppdateratLon),
      organisationsNummer: Yup.string()
        .required('Fyll i organisationsnummer')
        .matches(personnummer, {
          message: content.errorOrganisationsnummer,
        })
        .test(
          'organisationsNummer',
          content.errorOrganisationsnummer,
          (value: string) => (value ? validateOrganizationNumber(value) : false)
        ),
      startDatum: Yup.date()
        .nullable()
        .required('Välj ett startdatum')
        .typeError('Du måste ange ett giltigt datum'),
    }),
  handleSubmit: (
    values,
    {
      props: { postRapporteraStartaEgetForetag, setPending },
      setSubmitting,
      resetForm,
    }
  ) => {
    const filteredData = omit(
      values.inkomstUppgift.harUppdateratLon
        ? [
            'files',
            'inkomstUppgift',
            values.harVaritAktivtArbetssokande === 'false' &&
              'arbetssokandeIntygTyp',
          ]
        : [
            'files',
            values.harVaritAktivtArbetssokande === 'false' &&
              'arbetssokandeIntygTyp',
          ],
      values
    )
    const formData = new FormData()
    const json = JSON.stringify(filteredData)
    const file = values.files[0]

    if (file && file.name !== '') {
      const trimmedFileName = trimPathInformation(file.name)
      formData.append(
        'file',
        file.fileStream,
        encodeRFC5987ValueChars(trimmedFileName)
      )
    }
    formData.append('data', json)
    postRapporteraStartaEgetForetag(formData)
    setSubmitting(false)
    setPending(true)

    setTimeout(() => {
      resetForm()
    }, 2000)
  },
  displayName: 'StartatEgetForetag',
})(StartatEgetForetag)
