import * as React from 'react'
import * as Yup from 'yup'
import { withFormik, Form, FormikProps } from 'formik'
import { Fragment } from 'react'
import { Box, Checkbox, FormControl, FormControlLabel } from '@mui/material'
import FormErrorMessage from '@local/src/Components/FormErrorMessage'
import HTMLMapper from '@local/src/Components/HTMLMapper'
import useResetForm from '@local/src/Utils/Hooks/useResetForm'

import FormSubmit from '../../../Components/FormSubmit'

import {
  IAtertagenUppsagning,
  IDataAtertagenUppsagning,
} from './AtertagenUppsagning.types'

export const AtertagenUppsagning: React.FunctionComponent<
  React.PropsWithChildren<
    IAtertagenUppsagning & FormikProps<IDataAtertagenUppsagning>
  >
> = ({
  content,
  handleChange,
  handleSubmit,
  handleBlur,
  errors: { godkann: godkannError },
  values: { godkann: godkannValue },
  touched,
  handleCancel,
  resetForm,
  isExpanded,
}) => {
  useResetForm(isExpanded, resetForm)

  const isError = touched.godkann && Boolean(godkannError)

  return (
    <Fragment>
      <Box paddingTop={1} paddingBottom={2}>
        <HTMLMapper body={content.informationText} />
      </Box>

      <Form>
        <FormControl error={isError} variant="standard" fullWidth>
          <FormControlLabel
            control={
              <Checkbox
                checked={godkannValue}
                color="primary"
                onChange={handleChange}
                onBlur={handleBlur}
                name="godkann"
              />
            }
            label={content.checkboxText}
          />
          {isError && <FormErrorMessage>{godkannError}</FormErrorMessage>}
        </FormControl>

        <FormSubmit
          submitText={content.buttonText}
          submitAction={handleSubmit}
          cancelAction={handleCancel}
        />
      </Form>
    </Fragment>
  )
}

export const validationSchema = (): Yup.ObjectSchema<{ godkann: boolean }> =>
  Yup.object().shape<{ godkann: boolean }>({
    godkann: Yup.bool().oneOf(
      [true],
      'Du måste markera att du läst och förstått att du hädanefter inte får stöd från TRR'
    ),
  })

export default withFormik<IAtertagenUppsagning, IDataAtertagenUppsagning>({
  validationSchema,
  mapPropsToValues: () => ({
    godkann: false,
  }),
  handleSubmit: (
    values,
    { props: { atertagenUppsagning, setPending }, setSubmitting, resetForm }
  ) => {
    setSubmitting(false)
    atertagenUppsagning()
    setPending(true)

    setTimeout(() => {
      resetForm()
    }, 2000)
  },
  displayName: 'AtertagenUppsagningForm',
})(AtertagenUppsagning)
