import React from 'react'

export interface IAppShellContext {
  url: string
  handleAgeKey: string
  enabledFeatures: string[]
}

export const AppShellContext = React.createContext<IAppShellContext>({
  url: '',
  handleAgeKey: '',
  enabledFeatures: [],
})
