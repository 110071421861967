import React from 'react'
import { HTMLMapperCCL } from '@trr/html-mapper'

interface HTMLMapperProps {
  body: string
  replaceValues?: Record<string, unknown>
  className?: string
}

const HTMLMapper = ({
  body = '',
  replaceValues,
  className,
}: HTMLMapperProps) => (
  <HTMLMapperCCL
    body={body}
    replaceValues={replaceValues}
    className={className}
  />
)

export default HTMLMapper
