import React, { FC } from 'react'
import * as Yup from 'yup'
import { Form, FormikProps, withFormik } from 'formik'
import FileUpload from '@local/Components/FileUpload'
import { setFieldValue as customSetFieldValue } from '@local/Utils/Helpers'
import { isNil, omit } from 'ramda'
import { addDays, isBefore, isAfter } from 'date-fns'
import { trrDate } from '@local/Utils/Helpers/formatDate'
import {
  encodeRFC5987ValueChars,
  trimPathInformation,
} from '@local/Utils/Helpers/fileHelpers'
import { generateErrorMessage } from '@local/Utils/Helpers/Forms/Forms.helpers'
import { DatePicker } from '@local/src/Components/DatePicker'
import { fileUploadContent } from '@local/src/Components/FileUpload/fileUpload.helpers'
import { IUniqueFile } from '@local/src/Components/FileUpload/FileUpload.types'
import HTMLMapper from '@local/src/Components/HTMLMapper'
import { Grid } from '@mui/material'
import useResetForm from '@local/src/Utils/Hooks/useResetForm'

import FormSubmit from '../../../Components/FormSubmit'

import {
  IAvgangsvederlag,
  IAvgangsvederlagFormValues,
} from './Avgangsvederlag.types'

const Avgangsvederlag: FC<
  IAvgangsvederlag & FormikProps<IAvgangsvederlagFormValues>
> = ({
  content,
  handleSubmit,
  errors: { files: errorFiles, vederlagsDatum: errorVederlagsdatum },
  setFieldValue,
  values: { vederlagsDatum, files },
  startDatum,
  sistaDagMedLon,
  handleCancel,
  touched,
  resetForm,
  isExpanded,
}) => {
  useResetForm(isExpanded, resetForm)

  const isVisible =
    vederlagsDatum && isBefore(new Date(vederlagsDatum), new Date(startDatum))

  const isFilesError = Boolean(touched.files && errorFiles)

  const uploadContent = fileUploadContent(
    content.attachmentButtonText,
    content.attachmentInformationText
  )

  return (
    <Form>
      <HTMLMapper body={content.informationText} />

      <Grid container spacing={2}>
        <Grid xs={12} sm={6} item>
          <DatePicker
            name="vederlagsDatum"
            value={vederlagsDatum && new Date(vederlagsDatum)}
            onChange={customSetFieldValue(setFieldValue, 'vederlagsDatum')}
            label={content.dateInputFieldHeader}
            minDate={
              sistaDagMedLon ? addDays(trrDate(sistaDagMedLon), 1) : undefined
            }
            errorMessage={generateErrorMessage({
              touched,
              selector: 'vederlagsDatum',
              errorMsg: errorVederlagsdatum ?? '',
            })}
          />
        </Grid>
      </Grid>

      {isVisible && (
        <FileUpload
          isLoadingUpload={false}
          content={uploadContent}
          inputName={'files'}
          files={files}
          setFieldValue={setFieldValue}
          errorMessage={isFilesError ? String(errorFiles) : ''}
        />
      )}
      <FormSubmit
        submitText={content.buttonText}
        submitAction={handleSubmit}
        cancelAction={handleCancel}
      />
    </Form>
  )
}

export default withFormik<IAvgangsvederlag, IAvgangsvederlagFormValues>({
  mapPropsToValues: () => ({
    vederlagsDatum: null,
    files: [],
  }),
  validationSchema: ({ startDatum }: IAvgangsvederlag) =>
    Yup.lazy((values: IAvgangsvederlagFormValues) =>
      Yup.object().shape({
        vederlagsDatum: Yup.date()
          .nullable()
          .required('Du måste ange vederlagsdatum')
          .typeError('Du måste ange ett giltigt vederlagsdatum'),

        files: Yup.mixed().test(
          'files',
          'Du måste bifoga beslut om ersättning',
          (files: IUniqueFile[]) =>
            isAfter(new Date(values.vederlagsDatum), new Date(startDatum)) ||
            !isNil(files[0])
        ),
      })
    ),
  handleSubmit: (
    values,
    {
      props: { startDatum, rapporteraAvgangsvederlag, setPending },
      setSubmitting,
      resetForm,
    }
  ) => {
    const formData = new FormData()
    const file = values.files[0]
    const filename = file?.name ?? ''
    const json = JSON.stringify(omit(['files'], values))

    if (isBefore(new Date(values.vederlagsDatum ?? ''), new Date(startDatum))) {
      const trimmedFileName = trimPathInformation(filename)
      formData.append(
        'file',
        file.fileStream,
        encodeRFC5987ValueChars(trimmedFileName)
      )
    }
    formData.append('data', json)

    rapporteraAvgangsvederlag(formData)
    setSubmitting(false)
    setPending(true)

    setTimeout(() => {
      resetForm()
    }, 2000)
  },
  displayName: 'Avgangsvederlag',
})(Avgangsvederlag)
