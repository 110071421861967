import * as React from 'react'
import { Box, Grid, Typography, useTheme } from '@mui/material'
import NextPlanOutlinedIcon from '@mui/icons-material/NextPlanOutlined'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined'
import ChecklistOutlinedIcon from '@mui/icons-material/ChecklistOutlined'
import PaymentsIcon from '@mui/icons-material/Payments'

import { IAgeStep } from '../NextStepAge.types'

export const AgeStepsIconMap: {
  swayingArrow: JSX.Element
  payments: JSX.Element
  lock: JSX.Element
  questionform: JSX.Element
  bulb: JSX.Element
} = {
  swayingArrow: <NextPlanOutlinedIcon fontSize="large" />,
  payments: <PaymentsIcon fontSize="large" />,
  lock: <LockOutlinedIcon fontSize="large" />,
  questionform: <ChecklistOutlinedIcon fontSize="large" />,
  bulb: <LightbulbOutlinedIcon fontSize="large" />,
}

export const AgeStep: React.FunctionComponent<
  React.PropsWithChildren<IAgeStep>
> = ({ header, icon, children, tooltip, testSelector }) => {
  const Header = header && (
    <Typography variant="h3" gutterBottom>
      {header}
      {tooltip}
    </Typography>
  )

  const Icon = icon.type
  const theme = useTheme()

  return (
    <Box py={2} data-testid={testSelector}>
      <Grid container spacing={1}>
        <Grid item xs={'auto'} mt={0.5} mr={1}>
          <Icon style={{ color: theme.palette.primary.main }} {...icon.props} />
        </Grid>

        <Grid xs={11} item>
          {Header}
          {children}
        </Grid>
      </Grid>
    </Box>
  )
}
