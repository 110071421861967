import { useEffect, useMemo, useState } from 'react'
import { useFormikContext } from 'formik'
import {
  IAkasseBeloppPeriod,
  IBeraknadAge,
  IDagpenningAkassaPeriod,
  IInkomstbasbeloppPeriod,
} from '@local/Raknesnurra/BeraknaAge/BeraknaAge.types'
import { IRaknesnurraFormValues } from '@local/Raknesnurra/Raknesnurra.types'

import {
  convertFodelsedatumToDate,
  hasReducedDaysDueToPension,
} from '../BeraknaAge/BeraknaAge.helpers'
import {
  getHasTillatenLon,
  getIs40EllerAldre,
  getIsYngreAnAgeMaxAlder,
} from '../BeraknaAge/KontrolleraAgeRattigheter'
import { beraknaAge } from '../BeraknaAge/BeraknaAge'

import { IResult } from './Result.types'

export const useResult = (
  dagPenningAkassaPerioder: IDagpenningAkassaPeriod[],
  inkomstbasbeloppPerioder: IInkomstbasbeloppPeriod[],
  akasseBeloppPerioder: IAkasseBeloppPeriod[]
): IResult => {
  const {
    values: { fodelsedatum, loneniva, sistaAnstallningsdag, uppsagningsar },
    isSubmitting,
    isValid,
  } = useFormikContext<IRaknesnurraFormValues>()

  const [showResult, setShowResult] = useState<boolean>(false)
  const [is40EllerAldre, setIs40EllerAldre] = useState<boolean>(false)
  const [isYngreAnAgeMaxAlder, setIsYngreAnAgeMaxAlder] =
    useState<boolean>(false)
  const [hasTillatenLon, setHasTillatenLon] = useState<boolean>(false)
  const [agePeriods, setAgePeriods] = useState<IBeraknadAge[]>([])
  const birthdate = convertFodelsedatumToDate(fodelsedatum?.toString() || '')

  const checkHasAge = useMemo(
    () => (): boolean => {
      const is40EllerAldreCheck = getIs40EllerAldre({
        fodelseDatum: birthdate,
        sistaAnstallningsdag,
      })

      const isYngreAnAgeMaxAlderCheck = getIsYngreAnAgeMaxAlder({
        fodelseDatum: birthdate,
        sistaAnstallningsdag,
      })

      const hasTillatenLonCheck = getHasTillatenLon({
        manadslon: loneniva,
        sistaAnstallningsdag,
        mojligaDagpenningar: dagPenningAkassaPerioder,
      })

      setIs40EllerAldre(is40EllerAldreCheck)
      setIsYngreAnAgeMaxAlder(isYngreAnAgeMaxAlderCheck)
      setHasTillatenLon(hasTillatenLonCheck)

      return (
        is40EllerAldreCheck && isYngreAnAgeMaxAlderCheck && hasTillatenLonCheck
      )
    },
    [birthdate, sistaAnstallningsdag, loneniva, dagPenningAkassaPerioder]
  )

  const allParametersSet = fodelsedatum && loneniva && !!sistaAnstallningsdag

  useEffect(() => {
    if (isValid && isSubmitting && allParametersSet) {
      const hasAge = checkHasAge()
      if (hasAge) {
        setAgePeriods(
          beraknaAge({
            manadslon: loneniva,
            sistaAnstallningsdag,
            uppsagningsar: uppsagningsar,
            fodelsedatum: fodelsedatum.toString(),
            mojligaDagpenningar: dagPenningAkassaPerioder,
            mojligaInkomstbasbelopp: inkomstbasbeloppPerioder,
            akasseBeloppPerioder: akasseBeloppPerioder,
          })
        )
      }
      setShowResult(true)
    }
  }, [
    allParametersSet,
    checkHasAge,
    isValid,
    isSubmitting,
    loneniva,
    fodelsedatum,
    sistaAnstallningsdag,
    uppsagningsar,
    dagPenningAkassaPerioder,
    inkomstbasbeloppPerioder,
    akasseBeloppPerioder,
  ])

  const isReducedDaysDueToPension = hasReducedDaysDueToPension(
    agePeriods,
    birthdate,
    sistaAnstallningsdag
  )

  return {
    showResult,
    hasTillatenLon,
    is40EllerAldre,
    isYngreAnAgeMaxAlder,
    agePeriods,
    isReducedDaysDueToPension,
  }
}
