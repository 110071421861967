import React, { FC } from 'react'
import * as Yup from 'yup'
import { withFormik, FormikProps, Form } from 'formik'
import HTMLMapper from '@local/src/Components/HTMLMapper'
import { isNil, omit } from 'ramda'
import { FileUpload } from '@local/Components/FileUpload/FileUpload'
import {
  encodeRFC5987ValueChars,
  trimPathInformation,
} from '@local/Utils/Helpers/fileHelpers'
import { Box, Grid, TextField } from '@mui/material'
import { IUniqueFile } from '@local/src/Components/FileUpload/FileUpload.types'
import { fileUploadContent } from '@local/src/Components/FileUpload/fileUpload.helpers'
import useResetForm from '@local/src/Utils/Hooks/useResetForm'

import FormSubmit from '../../../Components/FormSubmit'

import {
  IAndraSkatteavdrag,
  IAndraSkatteavdragFormValues,
} from './AndraSkatteavdrag.types'

export const AndraSkatteavdrag: FC<
  IAndraSkatteavdrag & FormikProps<IAndraSkatteavdragFormValues>
> = ({
  content,
  handleChange,
  values: { skattesats, files },
  values,
  errors: { skattesats: skattesatsError, files: filesError },
  setFieldValue,
  handleSubmit,
  handleCancel,
  handleBlur,
  resetForm,
  touched,
  isExpanded,
}) => {
  useResetForm(isExpanded, resetForm)

  const displayBilaga = skattesats !== null && skattesats < 30
  const uploadContent = fileUploadContent(
    content.attachmentButtonText,
    content.attachmentInformationText
  )

  const isSkattesatsError = Boolean(touched.skattesats && skattesatsError)
  const isFilesError = Boolean(touched.files && filesError)

  return (
    <Form>
      <Box paddingTop={1} paddingBottom={2}>
        <HTMLMapper
          body={content.informationText}
          replaceValues={{ '{skattesats}': skattesats }}
        />
      </Box>

      <Grid container spacing={2}>
        <Grid xs={12} sm={6} item>
          <TextField
            fullWidth
            error={isSkattesatsError}
            helperText={touched.skattesats && skattesatsError}
            label={content.inputFieldHeader}
            onChange={handleChange}
            name="skattesats"
            onBlur={handleBlur}
            value={values.skattesats}
            type="number"
          />
        </Grid>
      </Grid>

      {displayBilaga && (
        <FileUpload
          isLoadingUpload={false}
          content={uploadContent}
          inputName={'files'}
          files={files}
          setFieldValue={setFieldValue}
          errorMessage={isFilesError ? String(filesError) : ''}
        />
      )}
      <FormSubmit
        submitText={content.buttonText}
        submitAction={handleSubmit}
        cancelAction={handleCancel}
      />
    </Form>
  )
}

export default withFormik<IAndraSkatteavdrag, IAndraSkatteavdragFormValues>({
  mapPropsToValues: () => ({
    skattesats: null,
    files: [],
  }),
  validationSchema: () =>
    Yup.lazy((values: IAndraSkatteavdragFormValues) =>
      Yup.object().shape({
        skattesats: Yup.mixed().test(
          'skattesats',
          'Du måste fylla i en giltig procentsats',
          (value) => value !== null && value >= 0 && value <= 100
        ),
        files: Yup.mixed().test(
          'files',
          'Du måste bifoga beslut om skattejämkning',
          (files: IUniqueFile[]) => {
            const { skattesats } = values
            const isValidSkattesats = skattesats !== null && skattesats >= 30
            const isValidFile = !isNil(files[0])

            return isValidSkattesats || isValidFile
          }
        ),
      })
    ),
  handleSubmit: (
    values,
    { props: { andraSkatteavdrag, setPending }, setSubmitting, resetForm }
  ) => {
    const json = JSON.stringify(omit(['files'], values))
    const formData = new FormData()
    if (values.skattesats < 30) {
      const file = values.files[0]
      const trimmedFileName = trimPathInformation(file.name)
      formData.append(
        'file',
        file.fileStream,
        encodeRFC5987ValueChars(trimmedFileName)
      )
    }
    formData.append('data', json)
    andraSkatteavdrag(formData)
    setSubmitting(false)
    setPending(true)

    setTimeout(() => {
      resetForm()
    }, 2000)
  },
  displayName: 'AndraSkatteavdrag',
})(AndraSkatteavdrag)
