import React, { FC, useEffect } from 'react'
import { IRaknesnurraContent } from '@local/Raknesnurra/Raknesnurra.types'
import RaknesnurraV2 from '@local/Raknesnurra/Raknesnurra.container'
import {
  AppDispatch,
  useDispatch,
  useSelector,
} from '@local/Store/storeContext'
import { ageRakneparametrarLoaded as ageRakneparametrarLoadedSelector } from '@local/Raknesnurra/Raknesnurra.selectors'
import { getAgeRakneparametrar } from '@local/Raknesnurra/Raknesnurra.actions'

interface IRaknesnurraPageProps {
  content: IRaknesnurraContent
}

const RaknesnurraPage: FC<React.PropsWithChildren<IRaknesnurraPageProps>> = ({
  content,
}) => {
  const dispatch: AppDispatch = useDispatch()
  const ageRakneparametrarLoaded = useSelector(ageRakneparametrarLoadedSelector)
  const contentLoading = !('urlKey' in content)

  useEffect(() => {
    void dispatch(getAgeRakneparametrar())
  }, [dispatch])

  return !contentLoading && ageRakneparametrarLoaded ? (
    <RaknesnurraV2 content={content} />
  ) : null
}

export default RaknesnurraPage
