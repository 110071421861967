import * as React from 'react'
import * as Yup from 'yup'
import { Form, withFormik, FormikProps } from 'formik'
import FileUpload from '@local/Components/FileUpload'
import EpiFile from '@local/Components/EpiFile'
import { setFieldValue as customSetFieldValue } from '@local/Utils/Helpers'
import {
  encodeRFC5987ValueChars,
  trimPathInformation,
} from '@local/Utils/Helpers/fileHelpers'
import { DatePicker } from '@local/src/Components/DatePicker'
import { fileUploadContent } from '@local/src/Components/FileUpload/fileUpload.helpers'
import HTMLMapper from '@local/src/Components/HTMLMapper'
import { Box, Grid } from '@mui/material'
import useResetForm from '@local/src/Utils/Hooks/useResetForm'

import FormSubmit from '../../../Components/FormSubmit'

import {
  IIntygaArbetssokandeAktivitetstod,
  IIntygaArbetssokandeAktivitetstodFormValues,
} from './IntygaArbetssokandeAktivitetsstod.types'

const IntygaArbetssokandeAktivitetstod: React.FunctionComponent<
  React.PropsWithChildren<
    IIntygaArbetssokandeAktivitetstod &
      FormikProps<IIntygaArbetssokandeAktivitetstodFormValues>
  >
> = ({
  content,
  values: { filesArbetssokande, filesAktivitetsstod, startDatum, slutDatum },
  errors: {
    startDatum: errorFranDatum,
    slutDatum: errorSlutDatum,
    filesArbetssokande: errorFilesArbetssokande,
    filesAktivitetsstod: errorFilesAktivitetsstod,
  },
  setFieldValue,
  handleSubmit,
  touched,
  handleCancel,
  resetForm,
  isExpanded,
}) => {
  useResetForm(isExpanded, resetForm)

  const arbetssokandeUploadContent = fileUploadContent(
    content.attachmentButtonText,
    content.arbetssokandedAttachmentInformationText
  )

  const aktivitetsstodUploadContent = fileUploadContent(
    content.attachmentButtonText,
    content.aktivitetsstodAttachmentInformationText
  )

  return (
    <Form>
      <HTMLMapper body={content.informationText} />

      <Grid container spacing={2}>
        <Grid xs={12} item>
          <EpiFile
            relLink={content.arbetssokandeExampleFile}
            label={content.arbetssokandeExampleFileLabel}
          />
        </Grid>

        <Grid xs={12} item>
          <FileUpload
            isLoadingUpload={false}
            content={arbetssokandeUploadContent}
            inputName={'filesArbetssokande'}
            files={filesArbetssokande}
            setFieldValue={setFieldValue}
            errorMessage={
              touched.filesArbetssokande && errorFilesArbetssokande
                ? String(errorFilesArbetssokande)
                : ''
            }
            testSelector="file-arbetssokande"
          />
        </Grid>

        <Grid xs={12} sm={6} item mt={2}>
          <DatePicker
            label={content.fromDateLabel}
            value={startDatum}
            maxDate={startDatum}
            name="startDatum"
            errorMessage={
              touched.startDatum && errorFranDatum !== ''
                ? (errorFranDatum as string)
                : ''
            }
            onChange={customSetFieldValue(setFieldValue, 'startDatum')}
          />
        </Grid>

        <Grid xs={12} sm={6} item />

        <Grid xs={12} sm={6} item>
          <DatePicker
            errorMessage={
              touched.slutDatum && errorSlutDatum !== ''
                ? (errorSlutDatum as string)
                : ''
            }
            label={content.toDateLabel}
            minDate={startDatum}
            value={slutDatum}
            name="slutDatum"
            onChange={customSetFieldValue(setFieldValue, 'slutDatum')}
          />
        </Grid>

        <Grid xs={12} sm={6} item />
      </Grid>

      <Box pt={4}>
        <EpiFile
          relLink={content.aktivitetsstodExampleFile}
          label={content.aktivitetsstodExampleFileLabel}
        />

        <FileUpload
          isLoadingUpload={false}
          content={aktivitetsstodUploadContent}
          inputName={'filesAktivitetsstod'}
          files={filesAktivitetsstod}
          setFieldValue={setFieldValue}
          errorMessage={
            touched.filesAktivitetsstod && errorFilesAktivitetsstod
              ? String(errorFilesAktivitetsstod)
              : ''
          }
          testSelector="file-aktivitetsstod"
        />
      </Box>

      <FormSubmit
        submitText={content.buttonText}
        submitAction={handleSubmit}
        cancelAction={handleCancel}
      />
    </Form>
  )
}

export default withFormik<
  IIntygaArbetssokandeAktivitetstod,
  IIntygaArbetssokandeAktivitetstodFormValues
>({
  mapPropsToValues: () => ({
    startDatum: undefined,
    slutDatum: undefined,
    filesAktivitetsstod: [],
    filesArbetssokande: [],
  }),
  validationSchema: () =>
    Yup.object().shape({
      startDatum: Yup.date()
        .nullable()
        .required('Du måste välja ett datum')
        .typeError('Du måste ange ett giltigt datum'),
      slutDatum: Yup.date()
        .nullable()
        .required('Du måste välja ett datum')
        .typeError('Du måste ange ett giltigt datum'),
      filesArbetssokande: Yup.array().required('Du måste välja en fil'),
      filesAktivitetsstod: Yup.array().required('Du måste välja en fil'),
    }),

  handleSubmit: (
    values,
    {
      props: { intygaArbetssokandeAktivitetsstod, setPending },
      setSubmitting,
      resetForm,
    }
  ) => {
    const json = JSON.stringify({
      aktivitetsstod: {
        startDatum: values.startDatum,
        slutDatum: values.slutDatum,
      },
    })
    const formData = new FormData()
    const fileArbetssokande = values.filesArbetssokande[0]
    const fileAktivitetsstod = values.filesAktivitetsstod[0]

    const trimmedFileNameArbetssokande = trimPathInformation(
      fileArbetssokande?.name ?? ''
    )
    const trimmedFileNameAktivitetsstod = trimPathInformation(
      fileAktivitetsstod?.name ?? ''
    )
    formData.append(
      'intyg-arbetssokande',
      fileArbetssokande.fileStream,
      encodeRFC5987ValueChars(trimmedFileNameArbetssokande)
    )
    formData.append(
      'intyg-aktivitetsstod',
      fileAktivitetsstod.fileStream,
      encodeRFC5987ValueChars(trimmedFileNameAktivitetsstod)
    )
    formData.append('data', json)
    intygaArbetssokandeAktivitetsstod(formData)
    setSubmitting(false)
    setPending(true)

    setTimeout(() => {
      resetForm()
    }, 2000)
  },
  displayName: 'AktivitetstodArbetssokande',
})(IntygaArbetssokandeAktivitetstod)
