import { useContext } from 'react'
import { AppShellContext } from '@local/src/AppShellContext'
import { includes } from 'ramda'

const useIsFeatureFlagEnabled = (featureFlag: string): boolean => {
  const appShellContext = useContext(AppShellContext)
  const enabledFeatures = appShellContext.enabledFeatures
  return includes(featureFlag, enabledFeatures)
}

export default useIsFeatureFlagEnabled
