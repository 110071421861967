import {
  object as yupObject,
  mixed as yupMixed,
  number as yupNumber,
  date as yupDate,
  Schema,
} from 'yup'

import { ISelectOption } from '../Components/ISelectOption'

import { IsCorrectFodelsedatum } from './BeraknaAge/BeraknaAge.helpers'
import {
  IRaknesnurraFormContent,
  IRaknesnurraFormValues,
} from './Raknesnurra.types'

const raknesnurraValidationSchema = (
  content: IRaknesnurraFormContent
): Schema<IRaknesnurraFormValues> =>
  yupObject().shape({
    fodelsedatum: yupNumber()
      .nullable()
      .test(
        'fodelsedatum',
        content.validationRequiredFodelsedatum,
        (value: number) => IsCorrectFodelsedatum(value)
      )
      .required(content.validationRequiredFodelsedatum),
    uppsagningsar: yupMixed()
      .nullable()
      .required(content.validationRequiredUppsagningsar),

    sistaAnstallningsdag: yupDate()
      .nullable()
      .required(content.validationRequiredSistaAnstallningsdag)
      .typeError('Du måste ange ett giltigt datum')
      .when(
        ['uppsagningsar'],
        (uppsagningsar: string, schema: Schema<ISelectOption>) =>
          schema.test({
            test: (sistaAnstallningsdag: Date) =>
              sistaAnstallningsdag?.getFullYear() >= parseInt(uppsagningsar),
            message: content.validationTestSistaAnstallningsdag,
          })
      ),
    loneniva: yupNumber()
      .nullable()
      .required(content.validationRequiredLon)
      .test(
        'loneniva',
        content.validationTestLon,
        (value: number) => value && value > 0
      ),
  })

export default raknesnurraValidationSchema
