import { connect } from 'react-redux'
import { RootState } from '@local/Store/configureStore'
import { AgeContext } from '@local/Store/storeContext'

import { postRapporteraStartaEgetForetag, setPending } from '../../Age.actions'
import { inkomstUppgift, foretagNamn } from '../../Age.selectors'

import StartatEgetForetag from './StartatEgetForetag'

const connectedComponent = connect(
  (state: RootState) => ({
    inkomstUppgift: inkomstUppgift(state),
    foretagNamn: foretagNamn(state),
  }),
  { postRapporteraStartaEgetForetag, setPending },
  null,
  { context: AgeContext }
)(StartatEgetForetag)

export default connectedComponent
