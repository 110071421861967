import {
  encodeRFC5987ValueChars,
  trimPathInformation,
} from '@local/src/Utils/Helpers/fileHelpers'
import { omit } from 'ramda'

import { IStartatEgetForetagFormValues } from './StartatEgetForetag.types'

export const getFormData = (values: IStartatEgetForetagFormValues) => {
  const filteredData = omit(
    [
      'files',
      values.inkomstUppgift?.harUppdateratLon && 'inkomstUppgift',
      values.harVaritAktivtArbetssokande === 'false' && 'arbetssokandeIntygTyp',
    ],
    values
  )

  const formData = new FormData()
  const json = JSON.stringify(filteredData)
  const file = values.files[0]

  if (file && file.name !== '') {
    const trimmedFileName = trimPathInformation(file.name)
    formData.append(
      'file',
      file.fileStream,
      encodeRFC5987ValueChars(trimmedFileName)
    )
  }

  formData.append('data', json)

  return formData
}
