import * as React from 'react'
import { useFormik } from 'formik'
import { setFieldValue as customSetFieldValue } from '@local/Utils/Helpers'
import { trrDate } from '@local/Utils/Helpers/formatDate'
import { DatePicker } from '@local/src/Components/DatePicker'
import HTMLMapper from '@local/src/Components/HTMLMapper'
import { Grid } from '@mui/material'
import useResetForm from '@local/src/Utils/Hooks/useResetForm'
import { useEffect } from 'react'
import { useTrackCustomClick } from '@trr/gtm-tracking'

import FormSubmit from '../../../Components/FormSubmit'

import {
  IAteroppnatArende,
  IAteroppnatArendeFormValues,
} from './AteroppnatArende.types'
import validationSchema from './AteroppnatArende.schema'

const initialValues: IAteroppnatArendeFormValues = {
  arbetssokandeStartDatum: null,
}

const AteroppnatArende = ({
  formName,
  handleCancel,
  content,
  isExpanded,
  isPending,
  rapporteraAteroppnatArende,
  setPending,
}: IAteroppnatArende) => {
  const customEventTracking = useTrackCustomClick()

  const formSubmit = (values: IAteroppnatArendeFormValues) => {
    rapporteraAteroppnatArende(values)
    setPending(true)

    customEventTracking(formName, {
      label: 'skicka',
    })
  }

  const formik = useFormik({
    onSubmit: formSubmit,
    initialValues,
    validationSchema,
  })

  const { setSubmitting } = formik

  useEffect(() => {
    setSubmitting(false)
  }, [isPending, setSubmitting])

  useResetForm(isExpanded, formik.resetForm, initialValues)

  return (
    <form onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
      <HTMLMapper body={content.informationText} />

      <Grid container mt={2}>
        <Grid xs={12} sm={6} item>
          <DatePicker
            onChange={customSetFieldValue(
              formik.setFieldValue,
              'arbetssokandeStartDatum'
            )}
            name="arbetssokandeStartDatum"
            label={content.dateInputFieldLabel}
            errorMessage={
              formik.touched.arbetssokandeStartDatum &&
              formik.errors.arbetssokandeStartDatum
                ? String(formik.errors.arbetssokandeStartDatum)
                : ''
            }
            value={formik.values.arbetssokandeStartDatum}
            maxDate={trrDate()}
          />
        </Grid>
      </Grid>

      <FormSubmit
        submitText={content.buttonText}
        submitAction={formik.handleSubmit}
        cancelAction={handleCancel}
      />
    </form>
  )
}

export default AteroppnatArende
