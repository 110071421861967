import { HubConnection, HubConnectionState } from '@microsoft/signalr'
import { getAge } from '@local/Age/Age.actions'
import { Dispatch } from 'redux'

import { showSystemFeedback } from '../Components/SystemFeedback/SystemFeedback.actions'
import { defaultErrorMessage } from '../Components/SystemFeedback/SystemFeedback'

import { SignalRRoom } from './SignalR.types'

export interface ISignalRMessage {
  UserId: string
  Domain: string
  NotificationType: string
  messageContent: string
}

const ConnectNotification = (
  connection: HubConnection,
  room: SignalRRoom,
  dispatch: Dispatch
) => {
  connection.on(room, () => {
    dispatch(showSystemFeedback(defaultErrorMessage))
    dispatch({ type: 'klientArendeUpdated' })
  })
}

const ConnectKlientArendeUpdated = (
  connection: HubConnection,
  room: SignalRRoom,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>
) => {
  connection.on(room, () => {
    dispatch(getAge({ noReload: true }))
  })
}

export const connectToSignalRRoom = ({
  connection,
  room,
  dispatch,
}: {
  connection: HubConnection
  room: SignalRRoom
  dispatch: Dispatch
}): void => {
  if (connection) {
    const connectFunction = () =>
      room === 'KlientArendeUpdated'
        ? ConnectKlientArendeUpdated(connection, room, dispatch)
        : ConnectNotification(connection, room, dispatch)
    if (connection.state === HubConnectionState.Connected) {
      connectFunction()
    } else {
      setTimeout(() => {
        connectFunction()
      }, 3000)
    }
  }
}

export const disconnectFromSignalRRoom = ({
  connection,
  room,
}: {
  connection: HubConnection
  room: SignalRRoom
}): void => {
  if (connection) {
    connection.off(room)
  }
}
