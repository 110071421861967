import React from 'react'
import { formatCurrencySEK } from '@local/Utils/Helpers'
import { Box, Grid, Typography } from '@mui/material'
import InformationPopper from '@local/src/Components/InformationPopper'

import { IAgePeriod } from './AgePeriod.types'

export const AgePeriod = ({ agePeriod, content }: IAgePeriod): JSX.Element => {
  const beloppPerDagText = `${formatCurrencySEK(agePeriod.beloppPerDag)}/dag`
  const totalBeloppText = `${formatCurrencySEK(agePeriod.totalBelopp)} ${
    content.totalText
  }`

  return (
    <Box data-testid="age-period" mb={2}>
      <Grid
        container
        sx={{
          alignItems: 'center',
        }}
      >
        <Grid item xs={12}>
          <Typography variant="body1" fontWeight="bold">
            Dag {agePeriod.startDay} - {agePeriod.lastDay}
          </Typography>
        </Grid>

        <Grid item xs="auto">
          <Typography variant="body2">
            {`${beloppPerDagText} | ${totalBeloppText}`}
          </Typography>
        </Grid>

        {agePeriod.hasUppnatMaxtak && (
          <Grid item xs="auto" alignItems="center" ml={1}>
            <InformationPopper
              text={content.maxtakInformation}
              id={`Popper-MaxTak-${agePeriod.startDay}`}
            />
          </Grid>
        )}

        {agePeriod.beloppPerDag === 0 && (
          <Grid item xs="auto" alignItems="center" ml={1}>
            <InformationPopper
              text={content.informationNollKrPerDagPopperText}
              id={`Popper-NollKr-${agePeriod.startDay}`}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  )
}
