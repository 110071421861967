import React, {
  useEffect,
  RefObject,
  useRef,
  useCallback,
  useState,
  useContext,
} from 'react'
import { useDidMount } from '@local/Utils/Hooks'
import { omit } from 'ramda'
import { AppShellContext } from '@local/src/AppShellContext'
import { useHistory } from 'react-router-dom'
import { Alert, Box, Snackbar, Typography, useTheme } from '@mui/material'
import HTMLMapper from '@local/src/Components/HTMLMapper'
import { showSystemFeedback } from '@local/src/Components/SystemFeedback/SystemFeedback.actions'
import { defaultErrorMessage } from '@local/src/Components/SystemFeedback/SystemFeedback'
import { useDispatch } from '@local/src/Store/storeContext'

import { findAvailableCategories, isDisabledHandelse } from '../Age.selectors'

import { IAgeHandelser } from './AgeHandelser.types'
import { AgeHandelseForm } from './AgeHandelse'

const scroll = ({ offsetTop }: { offsetTop: number }) =>
  window.scrollTo({
    top: offsetTop,
    behavior: 'smooth',
  })

const scrollElementIntoView = (currentForm: RefObject<HTMLDivElement>) => {
  const { current } = currentForm
  if (current !== null) {
    scroll(current)
  }
}

const AgeHandelser: React.FunctionComponent<
  React.PropsWithChildren<IAgeHandelser>
> = ({
  closeConfirmation,
  confirmSubmissionSuccess,
  content,
  getAge,
  klientHandelserAvailability,
  sortedAgeHandelser,
  skattesats,
  ageLoaded,
  arendeUpdated,
  formError,
  isPending,
}) => {
  const appShellContext = useContext(AppShellContext)
  const didMount = useDidMount()
  const history = useHistory()
  const activeFormRef = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch()
  const pageWithoutParams = appShellContext.url + appShellContext.handleAgeKey

  const [isSnackbarOpen, setIsSnackbarOpen] = React.useState<boolean>(false)

  const handleSnackbarClose = () => {
    setIsSnackbarOpen(false)
  }

  const formParam = !window.location.search
    ? ''
    : window.location.search.replace('?formular=', '')

  const [activeForm, setActiveForm] = useState<string>(formParam)

  const filteredAgeHandelser = findAvailableCategories(
    sortedAgeHandelser,
    klientHandelserAvailability
  )
  useEffect(() => {
    formParam === '' && setActiveForm('')
  }, [formParam])

  const sliceSlash = (url: string) =>
    url.endsWith('/') ? url.slice(0, -1) : url

  useEffect(() => {
    scrollElementIntoView(activeFormRef)
  }, [activeForm, ageLoaded])

  useEffect(() => {
    formError && dispatch(showSystemFeedback(defaultErrorMessage))
  }, [dispatch, formError])

  useEffect(() => {
    setActiveForm(formParam)
    history.push({
      pathname: `${sliceSlash(pageWithoutParams)}`,
      hash: formParam,
      search: `formular=${formParam}`,
    })
  }, [formParam, history, pageWithoutParams])

  useEffect(() => {
    arendeUpdated && getAge({ noReload: true })
  }, [getAge, arendeUpdated])

  const handleConfirmationClose = useCallback(() => {
    history.push(pageWithoutParams)
    closeConfirmation()
    getAge({ noReload: true })
  }, [history, pageWithoutParams, closeConfirmation, getAge])

  const theme = useTheme()

  useEffect(() => {
    if (didMount && confirmSubmissionSuccess) {
      setIsSnackbarOpen(true)
      handleConfirmationClose()
    }
  }, [
    confirmSubmissionSuccess,
    didMount,
    handleConfirmationClose,
    content.submitToasterText,
    theme.palette.secondary.main,
  ])

  return ageLoaded ? (
    <>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: '100%' }}
        >
          {content.submitToasterText ??
            'Din uppdatering har skickats för bearbetning'}
        </Alert>
      </Snackbar>

      <Box paddingTop={5} data-testid="age-handelser">
        <Box paddingBottom={2}>
          <Typography variant="h3" gutterBottom>
            {content.heading}
          </Typography>
          <Typography variant="preamble" gutterBottom>
            {content.preamble}
          </Typography>
        </Box>

        {filteredAgeHandelser.map(
          ({ list, form, title, block, availability, hasInkomstUppgift }) => {
            const blockContent = {
              ...form,
              ...(hasInkomstUppgift
                ? content.common
                : omit(['inkomstUppgift'], content.common)),
            }

            return (
              <AgeHandelseForm
                key={title}
                content={{
                  ...list,
                  description: (
                    <HTMLMapper
                      body={list?.description ?? ''}
                      {...(availability === 'andraSkatteAvdrag' && {
                        replaceValues: { '{Skattesats}': skattesats },
                      })}
                    />
                  ),
                }}
                isFormOpen={activeForm === availability}
                isDisabled={
                  isPending ||
                  isDisabledHandelse(klientHandelserAvailability[availability])
                }
                ref={activeFormRef}
                block={block}
                blockContent={blockContent}
              />
            )
          }
        )}
      </Box>
    </>
  ) : null
}

export default AgeHandelser
