import * as React from 'react'
import * as Yup from 'yup'
import { withFormik, Form, FormikProps } from 'formik'
import { setFieldValue as customSetFieldValue } from '@local/Utils/Helpers'
import { trrDate } from '@local/Utils/Helpers/formatDate'
import { DatePicker } from '@local/src/Components/DatePicker'
import HTMLMapper from '@local/src/Components/HTMLMapper'
import { Grid } from '@mui/material'
import useResetForm from '@local/src/Utils/Hooks/useResetForm'

import FormSubmit from '../../../Components/FormSubmit'

import {
  IAteroppnatArende,
  IAteroppnatArendeFormValues,
} from './AteroppnatArende.types'

const AteroppnatArende = ({
  handleSubmit,
  handleCancel,
  errors: { arbetssokandeStartDatum: errorArbetssokandeStartDatum },
  setFieldValue,
  values,
  touched,
  content,
  resetForm,
  isExpanded,
}: IAteroppnatArende & FormikProps<IAteroppnatArendeFormValues>) => {
  useResetForm(isExpanded, resetForm)

  return (
    <Form>
      <HTMLMapper body={content.informationText} />

      <Grid container mt={2}>
        <Grid xs={12} sm={6} item>
          <DatePicker
            onChange={customSetFieldValue(
              setFieldValue,
              'arbetssokandeStartDatum'
            )}
            name="arbetssokandeStartDatum"
            label={content.dateInputFieldLabel}
            errorMessage={
              touched.arbetssokandeStartDatum && errorArbetssokandeStartDatum
                ? String(errorArbetssokandeStartDatum)
                : ''
            }
            value={values.arbetssokandeStartDatum}
            maxDate={trrDate()}
          />
        </Grid>
      </Grid>

      <FormSubmit
        submitText={content.buttonText}
        submitAction={handleSubmit}
        cancelAction={handleCancel}
      />
    </Form>
  )
}

export default withFormik<IAteroppnatArende, IAteroppnatArendeFormValues>({
  mapPropsToValues: () => ({
    arbetssokandeStartDatum: null,
  }),
  validationSchema: () =>
    Yup.object()
      .nullable()
      .shape({
        arbetssokandeStartDatum: Yup.date()
          .nullable()
          .required('Du behöver fylla i ett datum')
          .typeError('Du behöver ange ett giltigt datum'),
      }),
  handleSubmit: (
    values,
    {
      props: { rapporteraAteroppnatArende, setPending },
      setSubmitting,
      resetForm,
    }
  ) => {
    setSubmitting(false)
    rapporteraAteroppnatArende(values)
    setPending(true)

    setTimeout(() => {
      resetForm()
    }, 2000)
  },
  displayName: 'AteroppnatArende',
})(AteroppnatArende)
