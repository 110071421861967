import { IIntygaArbetssokandeContent } from './IntygaArbetssokande.types'

export const getLabels = (
  arbetssokandeIntygTyp: string,
  content: IIntygaArbetssokandeContent
): {
  informationText: string
  attachmentLabel: string
  exampleFile: string
  exampleFileLabel: string
} => {
  if (arbetssokandeIntygTyp === 'specifikation-om-ersattning') {
    return {
      informationText: content.informationTextAkassa,
      attachmentLabel: content.attachmentLabelAkassa,
      exampleFile: content.akassaExampleFile,
      exampleFileLabel: content.akassaExampleFileLabel,
    }
  }
  return {
    informationText: content.informationTextArbetsformedling,
    attachmentLabel: content.attachmentLabelArbetsformedling,
    exampleFile: content.arbetsformedlingExampleFile,
    exampleFileLabel: content.arbetsformedlingExampleFileLabel,
  }
}
