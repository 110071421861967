import React, { FC } from 'react'
import * as Yup from 'yup'
import { FormikProps, withFormik, Form } from 'formik'
import HTMLMapper from '@local/src/Components/HTMLMapper'
import { setFieldValue as customSetFieldValue } from '@local/Utils/Helpers'
import { omit } from 'ramda'
import { generateErrorMessage } from '@local/Utils/Helpers/Forms/Forms.helpers'
import { DatePicker } from '@local/src/Components/DatePicker'
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import FormErrorMessage from '@local/src/Components/FormErrorMessage'
import useResetForm from '@local/src/Utils/Hooks/useResetForm'

import FormSubmit from '../../../Components/FormSubmit'

import {
  IArbetssokandeAvbrott,
  IArbetssokandeAvbrottFormValues,
  IArbetssokandeAvbrottSubmitValues,
} from './ArbetssokandeAvbrott.types'

export const ArbetssokandeAvbrott: FC<
  React.PropsWithChildren<
    IArbetssokandeAvbrott & FormikProps<IArbetssokandeAvbrottFormValues>
  >
> = ({
  content,
  errors: {
    avbrottsDatum: errorAvbrottsDatum,
    anstallningsForm: errorAnstallningsForm,
  },
  handleCancel,
  handleChange,
  handleSubmit,
  setFieldValue,
  resetForm,
  isExpanded,
  values: { avbrottsDatum, avbrottsOrsak, anstallningsForm },
  touched,
}) => {
  const handleSelectChange = (event: SelectChangeEvent) => {
    void setFieldValue('anstallningsForm', event.target.value)
  }

  const anstallningsFormError = generateErrorMessage({
    selector: 'anstallningsForm',
    errorMsg: errorAnstallningsForm ?? '',
    touched,
  })

  useResetForm(isExpanded, resetForm)

  return (
    <Form>
      <Box paddingTop={1} paddingBottom={2}>
        <HTMLMapper body={content.informationText} />
      </Box>

      <Grid container spacing={2}>
        <Grid xs={12} sm={6} item>
          <DatePicker
            onChange={customSetFieldValue(setFieldValue, 'avbrottsDatum')}
            errorMessage={generateErrorMessage({
              selector: 'avbrottsDatum',
              errorMsg: errorAvbrottsDatum ?? '',
              touched,
            })}
            label={content.dateInputFieldHeader}
            value={avbrottsDatum}
            name="avbrottsDatum"
          />
        </Grid>
      </Grid>

      <Box py={2}>
        <RadioGroup defaultValue="2" name="avbrottsOrsak">
          <Typography variant="body1">
            {content.avbrottsOrsakBulletPointHeader}
          </Typography>

          <FormControlLabel
            value="2"
            control={<Radio />}
            label="Ny anställning"
            onChange={handleChange}
          />
          <FormControlLabel
            value="1"
            control={<Radio />}
            label="Pension"
            onChange={handleChange}
          />
          <FormControlLabel
            value="7"
            control={<Radio />}
            label="Annat avbrott"
            onChange={handleChange}
          />
        </RadioGroup>
      </Box>

      {avbrottsOrsak === '2' && (
        <Grid container>
          <Grid xs={12} sm={6} item>
            <FormControl fullWidth>
              <InputLabel id="anstallningsForm-label">
                Anställningsform:
              </InputLabel>
              <Select
                labelId="anstallningsForm-label"
                name="anstallningsForm"
                label="Anställningsform:"
                value={anstallningsForm}
                onChange={handleSelectChange}
              >
                <MenuItem value="1">Tillsvidare</MenuItem>
                <MenuItem value="2">Visstid</MenuItem>
                <MenuItem value="3">Provanställning</MenuItem>
                <MenuItem value="4">Projektanställning</MenuItem>
              </Select>

              {anstallningsFormError?.length > 0 && (
                <FormErrorMessage>{anstallningsFormError}</FormErrorMessage>
              )}
            </FormControl>
          </Grid>
        </Grid>
      )}

      <FormSubmit
        submitText={content.buttonText}
        submitAction={handleSubmit}
        cancelAction={handleCancel}
      />
    </Form>
  )
}

export default withFormik<
  IArbetssokandeAvbrott,
  IArbetssokandeAvbrottFormValues
>({
  mapPropsToValues: () => ({
    avbrottsOrsak: '2',
    avbrottsDatum: null,
    anstallningsForm: null,
  }),
  validationSchema: () =>
    Yup.object().shape({
      avbrottsDatum: Yup.date()
        .nullable()
        .required('Du behöver ange ett datum för avbrott')
        .typeError('Du behöver ange ett giltigt datum för avbrott'),
      anstallningsForm: Yup.string()
        .nullable()
        .when('avbrottsOrsak', {
          is: (orsak) => orsak === '2',
          then: (value: Yup.StringSchema) =>
            value.required('Du behöver ange en anställningsform'),
        }),
    }),
  handleSubmit: (
    values,
    {
      props: { rapporteraArbetssokandeAvbrott, setPending },
      setSubmitting,
      resetForm,
    }
  ) => {
    // Remove occupation from values object and break out the number value from anstallningsform since backend expects a number
    const filteredData = omit(['anstallningsForm'], values)
    const anstallning = {
      anstallningsForm: values?.anstallningsForm,
    }
    const sendData: IArbetssokandeAvbrottSubmitValues = {
      ...filteredData,
      ...(values.avbrottsOrsak === '2' && anstallning),
    }

    setSubmitting(false)
    rapporteraArbetssokandeAvbrott(sendData)
    setPending(true)

    setTimeout(() => {
      resetForm()
    }, 2000)
  },
  displayName: 'RapporteraArbetssokandeAvbrottForm',
})(ArbetssokandeAvbrott)
