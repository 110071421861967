import * as React from 'react'
import * as Yup from 'yup'
import { Form, withFormik, FormikProps } from 'formik'
import HTMLMapper from '@local/src/Components/HTMLMapper'
import FileUpload from '@local/Components/FileUpload'
import { setFieldValue as customSetFieldValue } from '@local/Utils/Helpers'
import {
  encodeRFC5987ValueChars,
  trimPathInformation,
} from '@local/Utils/Helpers/fileHelpers'
import { DatePicker } from '@local/src/Components/DatePicker'
import { fileUploadContent } from '@local/src/Components/FileUpload/fileUpload.helpers'
import { IUniqueFile } from '@local/src/Components/FileUpload/FileUpload.types'
import { isNil } from 'ramda'
import { Grid } from '@mui/material'
import EpiFile from '@local/src/Components/EpiFile'
import useResetForm from '@local/src/Utils/Hooks/useResetForm'

import MinainkomstUppgiftForm from '../Common/MinaInkomstUppgifterForm/MinaInkomstUppgifterForm'
import FormSubmit from '../../../Components/FormSubmit'
import { inkomstUppgiftSchema } from '../Common/MinaInkomstUppgifterForm'
import { IMinaInkomstUppgifterFormContent } from '../Common/MinaInkomstUppgifterForm/MinaInkomstUppgifterForm.types'

import {
  IIntygaAktivitetstod,
  IIntygaAktivitetstodFormValues,
} from './IntygaAktivitetsstod.types'

export const Aktivitetstod: React.FunctionComponent<
  React.PropsWithChildren<
    IIntygaAktivitetstod & FormikProps<IIntygaAktivitetstodFormValues>
  >
> = ({
  content,
  handleChange,
  handleBlur,
  values: { files, startDatum, slutDatum, inkomstUppgift },
  values,
  errors: {
    startDatum: errorFranDatum,
    slutDatum: errorSlutDatum,
    files: errorFiles,
    inkomstUppgift: inkomstUppgiftErrors,
  },
  setFieldValue,
  handleSubmit,
  touched,
  handleCancel,
  foretagNamn,
  resetForm,
  isExpanded,
}) => {
  useResetForm(isExpanded, resetForm)

  const changeLoneRevisionsDatum = React.useCallback(
    (value: Date) => {
      void setFieldValue('inkomstUppgift.lonerevisionsDatum', value)
    },
    [setFieldValue]
  )

  const uploadContent = fileUploadContent(
    content.attachmentButtonText,
    content.attachmentInformationText
  )

  return (
    <Form>
      <EpiFile relLink={content.exampleFile} label={content.exampleFileLabel} />
      <HTMLMapper body={content.informationText} />

      {!inkomstUppgift.harUppdateratLon && (
        <MinainkomstUppgiftForm
          handleChangeDate={changeLoneRevisionsDatum}
          handleChange={handleChange}
          handleBlur={handleBlur}
          values={values.inkomstUppgift}
          content={content.inkomstUppgift as IMinaInkomstUppgifterFormContent}
          errors={inkomstUppgiftErrors}
          foretagNamn={foretagNamn}
        />
      )}

      <Grid container spacing={2} pt={2}>
        <Grid xs={12} sm={6} item>
          <DatePicker
            label={content.fromDateLabel}
            value={startDatum}
            maxDate={slutDatum}
            name="startDatum"
            errorMessage={
              touched.startDatum && errorFranDatum !== ''
                ? (errorFranDatum as string)
                : ''
            }
            onChange={customSetFieldValue(setFieldValue, 'startDatum')}
          />
        </Grid>

        <Grid item xs={6} />

        <Grid xs={12} sm={6} item>
          <DatePicker
            name="slutDatum"
            value={slutDatum}
            label={content.toDateLabel}
            minDate={startDatum}
            onChange={customSetFieldValue(setFieldValue, 'slutDatum')}
            errorMessage={
              touched.slutDatum && errorSlutDatum !== ''
                ? (errorSlutDatum as string)
                : ''
            }
          />
        </Grid>

        <Grid item xs={6} />

        <Grid xs={12} sm={6} item>
          <FileUpload
            isLoadingUpload={false}
            content={uploadContent}
            inputName={'files'}
            files={files}
            setFieldValue={setFieldValue}
            errorMessage={touched.files && errorFiles ? String(errorFiles) : ''}
          />
        </Grid>
      </Grid>

      <FormSubmit
        submitText={content.buttonText}
        submitAction={handleSubmit}
        cancelAction={handleCancel}
      />
    </Form>
  )
}

export default withFormik<IIntygaAktivitetstod, IIntygaAktivitetstodFormValues>(
  {
    mapPropsToValues: ({ inkomstUppgift }) => ({
      startDatum: undefined,
      slutDatum: undefined,
      inkomstUppgift,
      files: [],
    }),
    validationSchema: ({ inkomstUppgift }: IIntygaAktivitetstod) =>
      Yup.object().shape({
        startDatum: Yup.date()
          .nullable()
          .required('Du måste välja ett datum')
          .typeError('Du måste ange ett giltigt datum'),
        slutDatum: Yup.date()
          .nullable()
          .required('Du måste välja ett datum')
          .typeError('Du måste ange ett giltigt datum'),
        inkomstUppgift: inkomstUppgiftSchema(inkomstUppgift.harUppdateratLon),
        files: Yup.mixed().test(
          'files',
          'Du måste välja en fil',
          (files: IUniqueFile[]) => !isNil(files[0])
        ),
      }),

    handleSubmit: (
      values,
      { props: { intygaAktivitetstod, setPending }, setSubmitting, resetForm }
    ) => {
      const json = JSON.stringify({
        aktivitetsstod: {
          startDatum: values.startDatum,
          slutDatum: values.slutDatum,
        },
        ...(values.inkomstUppgift.harUppdateratLon
          ? null
          : { inkomstUppgift: values.inkomstUppgift }),
      })

      const formData = new FormData()
      const file = values.files[0]

      if (file) {
        const trimmedFileName = trimPathInformation(file.name)
        formData.append(
          'file',
          file.fileStream,
          encodeRFC5987ValueChars(trimmedFileName)
        )
        formData.append('data', json)
      }

      intygaAktivitetstod(formData)
      setSubmitting(false)
      setPending(true)

      setTimeout(() => {
        resetForm()
      }, 2000)
    },
    displayName: 'Aktivitetstod',
  }
)(Aktivitetstod)
