import React, { useEffect } from 'react'
import AgeHandelser from '@local/Age/AgeHandelser'
import {
  AppDispatch,
  useDispatch,
  useSelector,
} from '@local/Store/storeContext'
import { cleanAge, getAge } from '@local/Age/Age.actions'
import { ageLoaded as ageLoadedSelector } from '@local/Age/Age.selectors'

interface IHandleAgeProps {
  content: Record<string, unknown>
}

const HandleAge: React.FunctionComponent<
  React.PropsWithChildren<IHandleAgeProps>
> = ({ content }) => {
  const dispatch: AppDispatch = useDispatch()

  const contentLoading = !('andraSkatteavdrag' in content)
  const ageLoaded = useSelector(ageLoadedSelector)

  useEffect(() => {
    void dispatch(getAge())
    return () => void dispatch(cleanAge())
  }, [dispatch])

  return !contentLoading && ageLoaded ? (
    <AgeHandelser content={content} />
  ) : null
}

export default HandleAge
