import React from 'react'
import { formatCurrencySEK } from '@local/Utils/Helpers'
import { propOr } from 'ramda'
import { DatePicker } from '@local/src/Components/DatePicker'
import { Box, Grid, TextField, Typography } from '@mui/material'

import { IMinaInkomstUppgifterForm } from './MinaInkomstUppgifterForm.types'
import { sumSalary } from './MinaInkomstUppgifterForm.helper'

const MinaInkomstUppgifterForm: React.FunctionComponent<
  IMinaInkomstUppgifterForm
> = ({
  content,
  handleChange,
  handleChangeDate,
  handleBlur,
  values,
  foretagNamn,
  errors,
}) => (
  <Box>
    <Grid container data-testid="minaInkomstUppgifterForm" spacing={2}>
      <Grid sm={2} xs={12} item>
        <Typography variant="body2">
          {propOr('Tidigare arbetsgivare', 'tidigareForetagText', content)}
        </Typography>
      </Grid>
      <Grid sm={10} xs={12} item>
        <Typography sx={{ fontWeight: 'bold' }} variant="body2">
          {foretagNamn}
        </Typography>
      </Grid>

      <Grid sm={2} xs={12} item>
        <Typography variant="body2">
          {propOr('Total lön', 'totalLonText', content)}
        </Typography>
      </Grid>
      <Grid sm={10} xs={12} item>
        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
          {formatCurrencySEK(sumSalary(values))}
        </Typography>
      </Grid>
    </Grid>

    <Grid container spacing={2} mt={4} wrap="nowrap">
      <Grid sm={6} xs={10} item>
        <TextField
          fullWidth
          error={Boolean(errors?.manadsLon)}
          helperText={errors?.manadsLon}
          label={propOr('Månadslön i', 'manadsLonText', content)}
          onChange={handleChange}
          name="inkomstUppgift.manadsLon"
          onBlur={handleBlur}
          value={values.manadsLon.toString()}
          type="number"
        />
      </Grid>
      <Grid sm={6} xs item>
        <Typography variant="body2">kr/mån</Typography>
      </Grid>
    </Grid>

    <Grid container spacing={2} mt={1} wrap="nowrap">
      <Grid sm={6} xs={10} item>
        <TextField
          fullWidth
          error={Boolean(errors?.provision)}
          helperText={errors?.provision}
          label={propOr(
            'Belopp bonus/Provision/Tantiem',
            'provisionText',
            content
          )}
          onChange={handleChange}
          name="inkomstUppgift.provision"
          onBlur={handleBlur}
          value={values.provision.toString()}
          type="number"
        />
      </Grid>
      <Grid sm={6} xs item>
        <Typography variant="body2">kr/mån</Typography>
      </Grid>
    </Grid>

    <Grid container spacing={2} mt={1} wrap="nowrap">
      <Grid sm={6} xs={10} item>
        <TextField
          fullWidth
          error={Boolean(errors?.jour)}
          helperText={errors?.jour}
          label={propOr('Belopp skift/Jour/Ob-tillägg', 'jourText', content)}
          onChange={handleChange}
          name="inkomstUppgift.jour"
          onBlur={handleBlur}
          value={values.jour.toString()}
          type="number"
        />
      </Grid>

      <Grid sm={6} xs item>
        <Typography variant="body2">kr/mån</Typography>
      </Grid>
    </Grid>

    <Grid container spacing={2} mt={1} wrap="nowrap">
      <Grid sm={6} xs={12} item>
        <DatePicker
          value={
            values.lonerevisionsDatum && new Date(values.lonerevisionsDatum)
          }
          onChange={handleChangeDate}
          name="inkomstUppgift.lonerevisionsDatum"
          label={propOr(
            'Lönen ändrades senast',
            'lonerevisionsDatumText',
            content
          )}
          errorMessage={errors?.lonerevisionsDatum}
        />
      </Grid>
    </Grid>
  </Box>
)

export default MinaInkomstUppgifterForm
