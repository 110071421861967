import * as React from 'react'
import * as Yup from 'yup'
import { withFormik, Form, FormikProps } from 'formik'
import { isNil, omit } from 'ramda'
import { addBusinessDays } from 'date-fns'
import useResetForm from '@local/src/Utils/Hooks/useResetForm'
import { trrFormat } from '@local/src/Utils/Helpers/formatDate'

import { inkomstUppgiftSchema } from '../Common/MinaInkomstUppgifterForm'
import FormSubmit from '../../../Components/FormSubmit'

import {
  IForsakraArbetssokandeFormValues,
  IForsakraArbetssokande,
} from './ForsakraArbetssokande.types'
import ForsakraArbetssokandeForm from './ForsakraArbetssokandeForm'

const ForsakraArbetssokande = ({
  formName,
  startDatum,
  content,
  handleSubmit,
  handleChange,
  isDisabled,
  values,
  errors,
  setFieldValue,
  touched,
  setFieldTouched,
  handleBlur,
  handleCancel,
  foretagNamn,
  resetForm,
  isExpanded,
}: IForsakraArbetssokande &
  FormikProps<IForsakraArbetssokandeFormValues> & {
    handleCancel: () => void
  }) => {
  useResetForm(isExpanded, resetForm)

  return (
    <Form>
      <ForsakraArbetssokandeForm
        formName={formName}
        startDatum={startDatum}
        content={content}
        errors={errors}
        handleChange={handleChange}
        isDisabled={isDisabled}
        setFieldValue={setFieldValue}
        values={values}
        touched={touched}
        setFieldTouched={setFieldTouched}
        handleBlur={handleBlur}
        foretagNamn={foretagNamn}
      />

      <FormSubmit
        submitText={content.buttonText}
        submitAction={handleSubmit}
        cancelAction={handleCancel}
      />
    </Form>
  )
}

export default withFormik<
  IForsakraArbetssokande,
  IForsakraArbetssokandeFormValues
>({
  mapPropsToValues: ({ inkomstUppgift }) => ({
    anstallningsForm: null,
    avbrottsOrsak: null,
    arbetssokandeStartDatum: null,
    avbrottStartDatum: null,
    forsakran: false,
    inkomstUppgift,
    isAvbrytaArbetssokande: 'false',
    isInskrivenSomArbetssokande: 'true',
  }),
  validationSchema: ({
    content,
    inkomstUppgift,
    startDatum,
  }: IForsakraArbetssokande) =>
    Yup.object().shape({
      inkomstUppgift: inkomstUppgiftSchema(inkomstUppgift.harUppdateratLon),
      arbetssokandeStartDatum: Yup.date()
        .nullable()
        .typeError('Du behöver ange ett giltigt datum')
        .min(
          trrFormat(startDatum),
          'Du har angett ett datum som ligger före AGE start'
        )
        .max(new Date(), 'Du har angett ett datum som ligger i framtiden')

        .when('isInskrivenSomArbetssokande', {
          is: 'true',
          then: (value: Yup.ObjectSchema) =>
            value.required('Du behöver välja ett datum'),
        }),
      avbrottStartDatum: Yup.date()
        .typeError('Du behöver välja ett datum')
        .when(
          ['arbetssokandeStartDatum', 'isAvbrytaArbetssokande'],
          (
            arbetssokandeStartDatum: Date,
            isAvbrytaArbetssokande: string,
            schema: Yup.DateSchema
          ) => {
            if (isAvbrytaArbetssokande === 'true') {
              const addedDays = addBusinessDays(
                new Date(arbetssokandeStartDatum),
                30
              )
              return schema.max(
                addedDays,
                'Du har angett ett datum som ligger längre fram än 30 dagar från AGE start'
              )
            }
            return schema.nullable()
          }
        ),
      isInskrivenSomArbetssokande: Yup.string().test(
        'isInskrivenSomArbetssokande',
        content.inteInskrivenSomArbetssokandeText ??
          'För att få AGE måste du vara inskriven hos Arbetsförmedlingen',
        (value) => value === 'true'
      ),
      avbrottsOrsak: Yup.mixed().when('isAvbrytaArbetssokande', {
        is: 'true',
        then: (value: Yup.MixedSchema) =>
          value.required('Du behöver välja en avbrottsorsak'),
      }),
      anstallningsForm: Yup.string()
        .nullable()
        .when(['avbrottsOrsak', 'isAvbrytaArbetssokande'], {
          is: (orsak, isAvbrytaArbetssokande) =>
            isAvbrytaArbetssokande === 'true' && orsak === '2',
          then: (value: Yup.StringSchema) =>
            value.required('Du behöver ange en anställningsform'),
        }),
      forsakran: Yup.bool().test(
        'forsakran',
        'Du måste försäkra att de uppgifter du lämnat är sanningsenliga',
        (value: boolean) => value === true
      ),
    }),
  handleSubmit: (
    values,
    {
      props: { forsakraArbetssokande, foretagNamn, setPending },
      setSubmitting,
      resetForm,
    }
  ) => {
    const mergedData = {
      ...values,
      foretagNamn,
    }

    const filteredData = omit(
      [
        'isInskrivenSomArbetssokande',
        'forsakran',
        isNil(values.anstallningsForm) && 'anstallningsForm',
        values.inkomstUppgift.harUppdateratLon && 'inkomstUppgift',
        values.inkomstUppgift.harUppdateratLon && 'foretagNamn',
        values.isAvbrytaArbetssokande === 'false' && 'avbrottStartDatum',
        values.isAvbrytaArbetssokande === 'false' && 'avbrottsOrsak',
      ],
      mergedData
    )

    forsakraArbetssokande(filteredData)
    setSubmitting(false)
    setPending(true)

    setTimeout(() => {
      resetForm()
    }, 2000)
  },
  displayName: 'RapporteraAtertagenUppsagningForm',
})(ForsakraArbetssokande)
