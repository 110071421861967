// eslint-disable @typescript-eslint/explicit-module-boundary-types
import { createActions } from 'redux-actions'
import { get, put } from '@local/Utils/Network'

import { IArendeData } from './MyAge/MyAge.types'
import { IArbetssokandeAvbrottSubmitValues } from './Forms/ArbetssokandeAvbrott/ArbetssokandeAvbrott.types'
import { IAteroppnatArendeFormValues } from './Forms/AteroppnatArende/AteroppnatArende.types'
import { IForsakraArbetssokandeFormValues } from './Forms/ForsakraArbetssokande/ForsakraArbetssokande.types'
import { IDataSkrivUtBeslut } from './SkrivUtBeslut/SkrivUtBeslut.types'

export const {
  cleanAge,
  closeConfirmation,
  cleanSkrivUtBeslut,
  setPending,
  setPage,
} = createActions({
  cleanAge: () => ({}),
  closeConfirmation: () => ({}),
  cleanSkrivUtBeslut: () => ({}),
  setPending: (isPending: boolean) => isPending,
  setPage: (page: number) => page,
})

export const { getAgeInitiate, getAgeSuccess, getAgeFailure } = createActions({
  getAgeInitiate: (reload: boolean) => reload,
  getAgeSuccess: (data: IArendeData) => data,
  getAgeFailure: () => ({}),
})

export const getAge = ({ noReload = false }: { noReload?: boolean } = {}) =>
  get({
    fulfilled: getAgeSuccess,
    initiated: () => getAgeInitiate({ noReload }),
    rejected: getAgeFailure,
    url: '/ekonomisktstod/klient',
    appendUrl: '/age',
    appendSub: true,
  })

export const {
  atertagenUppsagningInitiate,
  atertagenUppsagningSuccess,
  atertagenUppsagningFailure,
} = createActions({
  atertagenUppsagningInitiate: () => ({}),
  atertagenUppsagningSuccess: () => ({}),
  atertagenUppsagningFailure: () => ({}),
})

export const atertagenUppsagning = () =>
  put({
    initiated: atertagenUppsagningInitiate,
    fulfilled: atertagenUppsagningSuccess,
    rejected: atertagenUppsagningFailure,
    url: '/ekonomisktstod/atertagen-uppsagning',
    appendSub: true,
  })

export const {
  skatteavdragInitiate,
  skatteavdragSuccess,
  skatteavdragFailure,
} = createActions({
  skatteavdragInitiate: () => ({}),
  skatteavdragSuccess: () => ({}),
  skatteavdragFailure: () => ({}),
})

export const andraSkatteavdrag = (data: FormData) =>
  put({
    initiated: skatteavdragInitiate,
    fulfilled: skatteavdragSuccess,
    rejected: skatteavdragFailure,
    url: '/ekonomisktstod/skatteavdrag',
    appendSub: true,
    multipart: true,
    body: data,
  })

export const {
  rapporteraAvgangsvederlagInitiate,
  rapporteraAvgangsvederlagSuccess,
  rapporteraAvgangsvederlagFailure,
} = createActions({
  rapporteraAvgangsvederlagInitiate: () => ({}),
  rapporteraAvgangsvederlagSuccess: () => ({}),
  rapporteraAvgangsvederlagFailure: () => ({}),
})

export const rapporteraAvgangsvederlag = (data: FormData) =>
  put({
    initiated: rapporteraAvgangsvederlagInitiate,
    fulfilled: rapporteraAvgangsvederlagSuccess,
    rejected: rapporteraAvgangsvederlagFailure,
    url: '/ekonomisktstod/avgangsvederlag',
    appendSub: true,
    multipart: true,
    body: data,
  })

export const {
  postRapporteraStartaEgetForetagInitiate,
  postRapporteraStartaEgetForetagSuccess,
  postRapporteraStartaEgetForetagFailure,
} = createActions({
  postRapporteraStartaEgetForetagInitiate: () => ({}),
  postRapporteraStartaEgetForetagSuccess: () => ({}),
  postRapporteraStartaEgetForetagFailure: () => ({}),
})

export const postRapporteraStartaEgetForetag = (data: FormData) =>
  put({
    initiated: postRapporteraStartaEgetForetagInitiate,
    fulfilled: postRapporteraStartaEgetForetagSuccess,
    rejected: postRapporteraStartaEgetForetagFailure,
    url: '/ekonomisktstod/startat-eget-foretag',
    appendSub: true,
    multipart: true,
    body: data,
  })

export const {
  rapporteraArbetssokandeAvbrottInitiate,
  rapporteraArbetssokandeAvbrottSuccess,
  rapporteraArbetssokandeAvbrottFailure,
} = createActions({
  rapporteraArbetssokandeAvbrottInitiate: () => ({}),
  rapporteraArbetssokandeAvbrottSuccess: () => ({}),
  rapporteraArbetssokandeAvbrottFailure: () => ({}),
})

export const rapporteraArbetssokandeAvbrott = (
  data: IArbetssokandeAvbrottSubmitValues
) =>
  put({
    initiated: rapporteraArbetssokandeAvbrottInitiate,
    fulfilled: rapporteraArbetssokandeAvbrottSuccess,
    rejected: rapporteraArbetssokandeAvbrottFailure,
    url: '/ekonomisktstod/arbetssokande-avbrott',
    appendSub: true,
    body: JSON.stringify(data),
  })

export const {
  rapporteraAteroppnatArendeInitiate,
  rapporteraAteroppnatArendeSuccess,
  rapporteraAteroppnatArendeFailure,
} = createActions({
  rapporteraAteroppnatArendeInitiate: () => ({}),
  rapporteraAteroppnatArendeSuccess: () => ({}),
  rapporteraAteroppnatArendeFailure: () => ({}),
})

export const rapporteraAteroppnatArende = (data: IAteroppnatArendeFormValues) =>
  put({
    initiated: rapporteraAteroppnatArendeInitiate,
    fulfilled: rapporteraAteroppnatArendeSuccess,
    rejected: rapporteraAteroppnatArendeFailure,
    url: '/ekonomisktstod/ateroppnat-arende',
    appendSub: true,
    body: JSON.stringify(data),
  })

export const {
  forsakraArbetssokandeInitiate,
  forsakraArbetssokandeSuccess,
  forsakraArbetssokandeFailure,
} = createActions({
  forsakraArbetssokandeInitiate: () => ({}),
  forsakraArbetssokandeSuccess: () => ({}),
  forsakraArbetssokandeFailure: () => ({}),
})

export const forsakraArbetssokande = (data: IForsakraArbetssokandeFormValues) =>
  put({
    initiated: forsakraArbetssokandeInitiate,
    fulfilled: forsakraArbetssokandeSuccess,
    rejected: forsakraArbetssokandeFailure,
    url: '/ekonomisktstod/forsakran-arbetssokande',
    appendSub: true,
    body: JSON.stringify(data),
  })

export const {
  intygaAktivitetstodInitiate,
  intygaAktivitetstodSuccess,
  intygaAktivitetstodFailure,
} = createActions({
  intygaAktivitetstodInitiate: () => ({}),
  intygaAktivitetstodSuccess: () => ({}),
  intygaAktivitetstodFailure: () => ({}),
})

export const intygaAktivitetstod = (data: FormData) =>
  put({
    initiated: intygaAktivitetstodInitiate,
    fulfilled: intygaAktivitetstodSuccess,
    rejected: intygaAktivitetstodFailure,
    url: '/ekonomisktstod/intyg-aktivitetsstod',
    appendSub: true,
    multipart: true,
    body: data,
  })

export const {
  rapporteraLonegarantiInitiate,
  rapporteraLonegarantiSuccess,
  rapporteraLonegarantiFailure,
} = createActions({
  rapporteraLonegarantiInitiate: () => ({}),
  rapporteraLonegarantiSuccess: () => ({}),
  rapporteraLonegarantiFailure: () => ({}),
})

export const lonegarantibeslut = (data: FormData) =>
  put({
    initiated: rapporteraLonegarantiInitiate,
    fulfilled: rapporteraLonegarantiSuccess,
    rejected: rapporteraLonegarantiFailure,
    url: '/ekonomisktstod/lonegaranti',
    appendSub: true,
    multipart: true,
    body: data,
  })

export const {
  intygaArbetssokandeInitiate,
  intygaArbetssokandeSuccess,
  intygaArbetssokandeFailure,
} = createActions({
  intygaArbetssokandeInitiate: () => ({}),
  intygaArbetssokandeSuccess: () => ({}),
  intygaArbetssokandeFailure: () => ({}),
})

export const intygaArbetssokande = (data: FormData) =>
  put({
    initiated: intygaArbetssokandeInitiate,
    fulfilled: intygaArbetssokandeSuccess,
    rejected: intygaArbetssokandeFailure,
    url: '/ekonomisktstod/intyg-arbetssokande',
    appendSub: true,
    multipart: true,
    body: data,
  })

export const {
  intygaArbetssokandeAktivitetsstodInitiate,
  intygaArbetssokandeAktivitetsstodSuccess,
  intygaArbetssokandeAktivitetsstodFailure,
} = createActions({
  intygaArbetssokandeAktivitetsstodInitiate: () => ({}),
  intygaArbetssokandeAktivitetsstodSuccess: () => ({}),
  intygaArbetssokandeAktivitetsstodFailure: () => ({}),
})

export const intygaArbetssokandeAktivitetsstod = (data: FormData) =>
  put({
    initiated: intygaArbetssokandeAktivitetsstodInitiate,
    fulfilled: intygaArbetssokandeAktivitetsstodSuccess,
    rejected: intygaArbetssokandeAktivitetsstodFailure,
    url: '/ekonomisktstod/arbetssokande-aktivitetsstod',
    appendSub: true,
    multipart: true,
    body: data,
  })

export const {
  getSkrivUtBeslutInitiate,
  getSkrivUtBeslutSuccess,
  getSkrivUtBeslutFailure,
} = createActions({
  getSkrivUtBeslutInitiate: () => ({}),
  getSkrivUtBeslutSuccess: (data: IDataSkrivUtBeslut) => data,
  getSkrivUtBeslutFailure: () => ({}),
})

export const getSkrivUtBeslut = () =>
  get({
    fulfilled: getSkrivUtBeslutSuccess,
    initiated: getSkrivUtBeslutInitiate,
    rejected: getSkrivUtBeslutFailure,
    url: '/ekonomisktstod/skriv-ut-beslut',
    appendSub: true,
  })

export const { clearFormError } = createActions({
  clearFormError: () => ({}),
})
