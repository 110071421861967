import { useSelector } from '@local/src/Store/storeContext'
import {
  startDatum as startDatumSelector,
  lockForsakraArbetssokande as lockForsakraArbetssokandeSelector,
  enableStartatEgetForetag as enableStartatEgetForetagSelector,
  lockStartatEgetForetag as lockStartatEgetForetagSelector,
  enableForsakraArbetssokande as enableForsakraArbetssokandeSelector,
} from '@local/Age/Age.selectors'

export const useActivatePaymentsData = () => {
  const startDatum = useSelector(startDatumSelector)
  const lockForsakraArbetssokande = useSelector(
    lockForsakraArbetssokandeSelector
  )
  const enableStartatEgetForetag = useSelector(enableStartatEgetForetagSelector)
  const lockStartatEgetForetag = useSelector(lockStartatEgetForetagSelector)

  const enableForsakraArbetssokande = useSelector(
    enableForsakraArbetssokandeSelector
  )

  return {
    startDatum,
    lockForsakraArbetssokande,
    enableStartatEgetForetag,
    enableForsakraArbetssokande,
    lockStartatEgetForetag,
  }
}

export default useActivatePaymentsData
