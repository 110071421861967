/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import { ActivateAge, HandleAge, Raknesnurra } from '@local/src/Pages'
import { AppShellRouter, KeyRoute } from '@local/src/AppShellRouter'
import { useDispatch } from '@local/Store/storeContext'
import {
  connectToSignalRRoom,
  disconnectFromSignalRRoom,
} from '@local/src/SignalR/signalRHelpers'
import { HubConnection } from '@microsoft/signalr'
import { createTheme, ThemeProvider } from '@mui/material'
import { themeOptions, defaultLocale } from '@trr/mui-theme'
import { LocalizationProvider, svSE } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { sv } from 'date-fns/locale'
import { injectStyle } from 'react-toastify/dist/inject-style'
import { GTMTracker } from '@trr/gtm-tracking'

import { IAppShellConfiguration } from './App.types'
import Store from './Store/configureStore'
import { AgeContext } from './Store/storeContext'
import { AppShellContext } from './AppShellContext'
import SystemFeedback from './Components/SystemFeedback/SystemFeedback'

injectStyle()

const SignalRWrapper: React.FunctionComponent<
  React.PropsWithChildren<{
    token: string
    connection: HubConnection
  }>
> = ({ children, connection }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    connectToSignalRRoom({
      connection: connection,
      room: 'Notification',
      dispatch,
    })
    connectToSignalRRoom({
      connection: connection,
      room: 'KlientArendeUpdated',
      dispatch,
    })

    return () => {
      disconnectFromSignalRRoom({
        connection: connection,
        room: 'KlientArendeUpdated',
      })
      disconnectFromSignalRRoom({
        connection: connection,
        room: 'Notification',
      })
    }
  }, [connection, dispatch])

  return <>{children}</>
}

const App: React.FunctionComponent<IAppShellConfiguration> = ({
  content,
  currentKey,
  url = '/klient/ekonomiskt-stod/min-avgangsersattning-ny/',
  idToken,
  signalRConnection,
  enabledFeatures,
}) => {
  const activatePaymentsKey = 'aktivera-min-age'
  const handleAgeKey = 'hantera-min-age'
  const raknesnurra = 'age-raknesnurra'
  const theme = createTheme(themeOptions, defaultLocale, svSE)

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
        <Provider store={Store} context={AgeContext}>
          <AppShellContext.Provider
            value={{ url, handleAgeKey, enabledFeatures }}
          >
            <SignalRWrapper
              token={idToken}
              connection={signalRConnection?.klient}
            >
              <GTMTracker mfName="age">
                <SystemFeedback />
                <AppShellRouter currentKey={currentKey}>
                  <KeyRoute urlKey={activatePaymentsKey}>
                    <ActivateAge content={content.aktiveraMinAge} />
                  </KeyRoute>
                  <KeyRoute urlKey={handleAgeKey}>
                    <HandleAge content={content.AgeHandelserBlock} />
                  </KeyRoute>
                  <KeyRoute urlKey={raknesnurra}>
                    <Raknesnurra content={content} />
                  </KeyRoute>
                </AppShellRouter>
              </GTMTracker>
            </SignalRWrapper>
          </AppShellContext.Provider>
        </Provider>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default App
