import { IUniqueFile } from '@local/src/Components/FileUpload/FileUpload.types'
import * as Yup from 'yup'
import { validateOrganizationNumber } from '@local/src/Utils/Helpers'
import { personnummer } from '@local/Utils/Helpers/regexes'

import { IInkomstuppgifter } from '../../MyAge/MyAge.types'
import { inkomstUppgiftSchema } from '../Common/MinaInkomstUppgifterForm'

import { IEkonomisktForetagsstodContent } from './EkonomisktForetagsstod.types'

export interface IValidationSchema {
  inkomstUppgift?: IInkomstuppgifter
  content?: IEkonomisktForetagsstodContent
  isFeatureFlagEnabled?: boolean
}

const validationSchema = ({
  content,
  inkomstUppgift,
  isFeatureFlagEnabled,
}: IValidationSchema) =>
  Yup.object().shape({
    agarandel: Yup.mixed().test(
      'agarandel',
      content.errorAgarandel,
      (value) => value !== null && value >= 33.3 && value <= 100
    ),
    arbetssokandeIntygTyp: Yup.string().when(['harVaritAktivtArbetssokande'], {
      is: 'true',
      then: (value: Yup.StringSchema) =>
        value.required('Du behöver välja ett alternativ'),
    }),
    fskattesedel: isFeatureFlagEnabled
      ? Yup.array().required(
          'Registreringsutdrag för F-skattsedel måste bifogas'
        )
      : Yup.object({}).nullable(),
    files: Yup.array().when(
      ['harVaritAktivtArbetssokande', 'arbetssokandeIntygTyp'],
      {
        is: (harVaritAktivtArbetssokande, arbetssokandeIntygTyp) =>
          harVaritAktivtArbetssokande === 'true' &&
          arbetssokandeIntygTyp !== 'a-kassa-samtycke',
        then: (value: Yup.ArraySchema<IUniqueFile>) =>
          value.required('Du behöver bifoga en fil'),
      }
    ),

    foretagsnamn: Yup.string().required('Fyll i ett företagsnamn'),
    harVaritAktivtArbetssokande: Yup.string().required(
      'Du behöver välja ett alternativ'
    ),
    inkomstUppgift: inkomstUppgiftSchema(inkomstUppgift?.harUppdateratLon),
    organisationsNummer: Yup.string()
      .required('Fyll i organisationsnummer')
      .matches(personnummer, {
        message: content.errorOrganisationsnummer,
      })
      .test(
        'organisationsNummer',
        content.errorOrganisationsnummer,
        (value: string) => (value ? validateOrganizationNumber(value) : false)
      ),
    startDatum: Yup.date()
      .nullable()
      .required('Välj ett startdatum')
      .typeError('Du måste ange ett giltigt datum'),
  })

export default validationSchema
