import React, { FC } from 'react'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import { Grid, Typography, useTheme } from '@mui/material'

import { ILocked } from './Locked.types'

const Locked: FC<React.PropsWithChildren<ILocked>> = ({
  content = {
    description: 'Dina uppgifter bearbetas',
  },
}) => {
  const theme = useTheme()

  return (
    <Grid container alignItems="center" justifyContent="center" spacing={1}>
      <Grid md={2} sm={6} item>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={(theme) => ({
            height: theme.spacing(5),
            width: theme.spacing(5),
            backgroundColor: theme.palette.accent.main,
            borderRadius: '100%',
          })}
        >
          <Grid item>
            <AccessTimeOutlinedIcon
              data-testid="locked"
              style={{
                color: theme.palette.common.white,
                height: theme.spacing(3),
                backgroundColor: theme.palette.accent.main,
                marginTop: '5px',
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid md={10} sm={6} item>
        <Typography variant="body1" align="center">
          {content.description}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default Locked
