import React, { createElement, forwardRef, RefObject, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Grid,
  Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Locked from '@local/src/Components/Locked'
import { useTrackCustomClick } from '@trr/gtm-tracking'
import { useIsFeatureFlagEnabled } from '@local/src/Utils/Hooks'

import { IAgeHandelseFormItem } from '../AgeHandelser.types'
import { IHandelseBlock } from '../AgeHandelseMap'

const AgeHandelseForm = forwardRef(
  (
    {
      content: { header, description },
      isFormOpen = false,
      isDisabled = false,
      formName,
      testSelector = 'age-handelse-form',
      block,
      blockContent,
    }: IAgeHandelseFormItem,
    ref: RefObject<HTMLDivElement>
  ) => {
    const isTrackingEnabled = useIsFeatureFlagEnabled('gtm.enabled.age')
    const customEventTracking = useTrackCustomClick()

    const [isExpanded, setIsExpanded] = useState<boolean>(isFormOpen)

    const handleChange = (expanded: boolean) => {
      setIsExpanded(expanded)

      const trackingAction = expanded ? `öppnades` : `stängdes`

      isTrackingEnabled &&
        customEventTracking(header, {
          label: trackingAction,
        })
    }

    const handleCancel = () => {
      setIsExpanded(false)

      isTrackingEnabled &&
        customEventTracking(header, {
          label: 'avbryt',
        })
    }

    const isActive = isExpanded && block

    if (isDisabled)
      return (
        <Card
          sx={(theme) => ({
            marginBottom: theme.spacing(2),
            borderRadius: theme.spacing(0.5),
          })}
        >
          <Grid container spacing={2} padding={2}>
            <Grid xs={6} sm={8} item>
              <Typography variant="h6">{header}</Typography>
            </Grid>
            <Grid xs={6} sm={4} item>
              <Locked />
            </Grid>
          </Grid>
        </Card>
      )

    return (
      <Accordion
        {...(isFormOpen ? { ref } : {})}
        data-testid={testSelector}
        data-form-name={formName}
        expanded={isExpanded}
        onChange={(event, expanded) => handleChange(expanded)}
        disabled={isDisabled}
        sx={(theme) => ({
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: theme.spacing(0.5),
          '&:not(:last-child)': {
            borderBottom: 0,
          },
          '&:before': {
            display: 'none',
          },
          mb: 2,
        })}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon sx={{ color: isActive ? 'white' : 'black' }} />
          }
          sx={(theme) => ({
            backgroundColor: isActive
              ? theme.palette.primary.main
              : theme.palette.grey[100],
            color: isActive ? 'white' : 'black',
          })}
        >
          <Box>
            {header && <Typography variant="h6">{header}</Typography>}
            {isDisabled && <Locked />}
            {description && (
              <Typography variant="body2">{description}</Typography>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box p={{ xs: 1, sm: 5 }}>
            {block &&
              createElement<Partial<IHandelseBlock>>(block, {
                content: blockContent,
                handleCancel: handleCancel,
                isExpanded: isExpanded,
                formName: formName,
              })}
          </Box>
        </AccordionDetails>
      </Accordion>
    )
  }
)

AgeHandelseForm.displayName = 'AgeHandelseForm'

export default AgeHandelseForm
