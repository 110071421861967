import * as React from 'react'
import getConfig from '@local/Utils/getConfig'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Grid, Typography, useTheme } from '@mui/material'

import s from './EpiFile.module.scss'

interface IEpiFile {
  relLink: string
  label: string
  testSelector?: string
}

const EpiFile: React.FunctionComponent<React.PropsWithChildren<IEpiFile>> = ({
  relLink,
  label,
  testSelector,
}) => {
  const theme = useTheme()
  const url = `${getConfig().MEDIA_URL}${relLink}`

  return (
    <Grid container py={1}>
      <Grid item xs="auto" mr={0.5}>
        <OpenInNewIcon style={{ color: theme.palette.primary.main }} />
      </Grid>
      <Grid item xs={10}>
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className={s.EpiLink}
          data-testid={testSelector}
        >
          <Typography variant="body1" color={theme.palette.primary.main}>
            {label}
          </Typography>
        </a>
      </Grid>
    </Grid>
  )
}

export default EpiFile
